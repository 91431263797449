dl {
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  position: relative;
  padding: 20px;
}

dt {
  align-self: flex-start;
  width: 100%;
  font-weight: 700;
  display: block;
  text-align: center;
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 20px;
  margin-left: 130px;
}

.info-text {
  white-space: nowrap;
  text-align: left;
  font-size: 1rem;
  color: rgb(102, 102, 102);
  line-height: 100%;
  margin-bottom: 0px;
  margin-top: 20px;
}

.btn-primary {
  background-color: rgb(34, 39, 37) !important;
  border-color: rgb(34, 39, 37) !important;
  max-height: 2rem;
  line-height: 0.8rem !important;
  font-size: small !important;
  border-radius: 2px !important;
  margin-top: 0.6rem;
}

.text {
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: end;
  height: 20px !important;
  width: 130px;
  background-color: white;
  position: absolute;
  left: 0;
  justify-content: flex-end;
}

.text-after {
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 40px;
  width: 130px;
  background-color: white;
  position: absolute;
  left: 140px;
  justify-content: flex-start;
}

.percentageir {
  font-size: 0.8em;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
  height: 16px;
  margin-left: 130px;
}
.percentageir:after {
  content: "";
  display: block;
  background-color: rgb(6, 214, 160);
  width: 50px;
  margin-bottom: 0px;
  height: 40%;
  border-radius: 10px;
  position: relative;
  top: 60%;
  transform: translateY(-50%);
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.percentageir:hover:after, .percentageir:focus:after {
  background-color: #aaa;
}

.percentageir-0:after {
  width: 0%;
}

.percentageir-1:after {
  width: 0.045%;
}

.percentageir-2:after {
  width: 0.09%;
}

.percentageir-3:after {
  width: 0.135%;
}

.percentageir-4:after {
  width: 0.18%;
}

.percentageir-5:after {
  width: 0.225%;
}

.percentageir-6:after {
  width: 0.27%;
}

.percentageir-7:after {
  width: 0.315%;
}

.percentageir-8:after {
  width: 0.36%;
}

.percentageir-9:after {
  width: 0.405%;
}

.percentageir-10:after {
  width: 0.45%;
}

.percentageir-11:after {
  width: 0.495%;
}

.percentageir-12:after {
  width: 0.54%;
}

.percentageir-13:after {
  width: 0.585%;
}

.percentageir-14:after {
  width: 0.63%;
}

.percentageir-15:after {
  width: 0.675%;
}

.percentageir-16:after {
  width: 0.72%;
}

.percentageir-17:after {
  width: 0.765%;
}

.percentageir-18:after {
  width: 0.81%;
}

.percentageir-19:after {
  width: 0.855%;
}

.percentageir-20:after {
  width: 0.9%;
}

.percentageir-21:after {
  width: 0.945%;
}

.percentageir-22:after {
  width: 0.99%;
}

.percentageir-23:after {
  width: 1.035%;
}

.percentageir-24:after {
  width: 1.08%;
}

.percentageir-25:after {
  width: 1.125%;
}

.percentageir-26:after {
  width: 1.17%;
}

.percentageir-27:after {
  width: 1.215%;
}

.percentageir-28:after {
  width: 1.26%;
}

.percentageir-29:after {
  width: 1.305%;
}

.percentageir-30:after {
  width: 1.35%;
}

.percentageir-31:after {
  width: 1.395%;
}

.percentageir-32:after {
  width: 1.44%;
}

.percentageir-33:after {
  width: 1.485%;
}

.percentageir-34:after {
  width: 1.53%;
}

.percentageir-35:after {
  width: 1.575%;
}

.percentageir-36:after {
  width: 1.62%;
}

.percentageir-37:after {
  width: 1.665%;
}

.percentageir-38:after {
  width: 1.71%;
}

.percentageir-39:after {
  width: 1.755%;
}

.percentageir-40:after {
  width: 1.8%;
}

.percentageir-41:after {
  width: 1.845%;
}

.percentageir-42:after {
  width: 1.89%;
}

.percentageir-43:after {
  width: 1.935%;
}

.percentageir-44:after {
  width: 1.98%;
}

.percentageir-45:after {
  width: 2.025%;
}

.percentageir-46:after {
  width: 2.07%;
}

.percentageir-47:after {
  width: 2.115%;
}

.percentageir-48:after {
  width: 2.16%;
}

.percentageir-49:after {
  width: 2.205%;
}

.percentageir-50:after {
  width: 2.25%;
}

.percentageir-51:after {
  width: 2.295%;
}

.percentageir-52:after {
  width: 2.34%;
}

.percentageir-53:after {
  width: 2.385%;
}

.percentageir-54:after {
  width: 2.43%;
}

.percentageir-55:after {
  width: 2.475%;
}

.percentageir-56:after {
  width: 2.52%;
}

.percentageir-57:after {
  width: 2.565%;
}

.percentageir-58:after {
  width: 2.61%;
}

.percentageir-59:after {
  width: 2.655%;
}

.percentageir-60:after {
  width: 2.7%;
}

.percentageir-61:after {
  width: 2.745%;
}

.percentageir-62:after {
  width: 2.79%;
}

.percentageir-63:after {
  width: 2.835%;
}

.percentageir-64:after {
  width: 2.88%;
}

.percentageir-65:after {
  width: 2.925%;
}

.percentageir-66:after {
  width: 2.97%;
}

.percentageir-67:after {
  width: 3.015%;
}

.percentageir-68:after {
  width: 3.06%;
}

.percentageir-69:after {
  width: 3.105%;
}

.percentageir-70:after {
  width: 3.15%;
}

.percentageir-71:after {
  width: 3.195%;
}

.percentageir-72:after {
  width: 3.24%;
}

.percentageir-73:after {
  width: 3.285%;
}

.percentageir-74:after {
  width: 3.33%;
}

.percentageir-75:after {
  width: 3.375%;
}

.percentageir-76:after {
  width: 3.42%;
}

.percentageir-77:after {
  width: 3.465%;
}

.percentageir-78:after {
  width: 3.51%;
}

.percentageir-79:after {
  width: 3.555%;
}

.percentageir-80:after {
  width: 3.6%;
}

.percentageir-81:after {
  width: 3.645%;
}

.percentageir-82:after {
  width: 3.69%;
}

.percentageir-83:after {
  width: 3.735%;
}

.percentageir-84:after {
  width: 3.78%;
}

.percentageir-85:after {
  width: 3.825%;
}

.percentageir-86:after {
  width: 3.87%;
}

.percentageir-87:after {
  width: 3.915%;
}

.percentageir-88:after {
  width: 3.96%;
}

.percentageir-89:after {
  width: 4.005%;
}

.percentageir-90:after {
  width: 4.05%;
}

.percentageir-91:after {
  width: 4.095%;
}

.percentageir-92:after {
  width: 4.14%;
}

.percentageir-93:after {
  width: 4.185%;
}

.percentageir-94:after {
  width: 4.23%;
}

.percentageir-95:after {
  width: 4.275%;
}

.percentageir-96:after {
  width: 4.32%;
}

.percentageir-97:after {
  width: 4.365%;
}

.percentageir-98:after {
  width: 4.41%;
}

.percentageir-99:after {
  width: 4.455%;
}

.percentageir-100:after {
  width: 4.5%;
}

.percentageir-101:after {
  width: 4.545%;
}

.percentageir-102:after {
  width: 4.59%;
}

.percentageir-103:after {
  width: 4.635%;
}

.percentageir-104:after {
  width: 4.68%;
}

.percentageir-105:after {
  width: 4.725%;
}

.percentageir-106:after {
  width: 4.77%;
}

.percentageir-107:after {
  width: 4.815%;
}

.percentageir-108:after {
  width: 4.86%;
}

.percentageir-109:after {
  width: 4.905%;
}

.percentageir-110:after {
  width: 4.95%;
}

.percentageir-111:after {
  width: 4.995%;
}

.percentageir-112:after {
  width: 5.04%;
}

.percentageir-113:after {
  width: 5.085%;
}

.percentageir-114:after {
  width: 5.13%;
}

.percentageir-115:after {
  width: 5.175%;
}

.percentageir-116:after {
  width: 5.22%;
}

.percentageir-117:after {
  width: 5.265%;
}

.percentageir-118:after {
  width: 5.31%;
}

.percentageir-119:after {
  width: 5.355%;
}

.percentageir-120:after {
  width: 5.4%;
}

.percentageir-121:after {
  width: 5.445%;
}

.percentageir-122:after {
  width: 5.49%;
}

.percentageir-123:after {
  width: 5.535%;
}

.percentageir-124:after {
  width: 5.58%;
}

.percentageir-125:after {
  width: 5.625%;
}

.percentageir-126:after {
  width: 5.67%;
}

.percentageir-127:after {
  width: 5.715%;
}

.percentageir-128:after {
  width: 5.76%;
}

.percentageir-129:after {
  width: 5.805%;
}

.percentageir-130:after {
  width: 5.85%;
}

.percentageir-131:after {
  width: 5.895%;
}

.percentageir-132:after {
  width: 5.94%;
}

.percentageir-133:after {
  width: 5.985%;
}

.percentageir-134:after {
  width: 6.03%;
}

.percentageir-135:after {
  width: 6.075%;
}

.percentageir-136:after {
  width: 6.12%;
}

.percentageir-137:after {
  width: 6.165%;
}

.percentageir-138:after {
  width: 6.21%;
}

.percentageir-139:after {
  width: 6.255%;
}

.percentageir-140:after {
  width: 6.3%;
}

.percentageir-141:after {
  width: 6.345%;
}

.percentageir-142:after {
  width: 6.39%;
}

.percentageir-143:after {
  width: 6.435%;
}

.percentageir-144:after {
  width: 6.48%;
}

.percentageir-145:after {
  width: 6.525%;
}

.percentageir-146:after {
  width: 6.57%;
}

.percentageir-147:after {
  width: 6.615%;
}

.percentageir-148:after {
  width: 6.66%;
}

.percentageir-149:after {
  width: 6.705%;
}

.percentageir-150:after {
  width: 6.75%;
}

.percentageir-151:after {
  width: 6.795%;
}

.percentageir-152:after {
  width: 6.84%;
}

.percentageir-153:after {
  width: 6.885%;
}

.percentageir-154:after {
  width: 6.93%;
}

.percentageir-155:after {
  width: 6.975%;
}

.percentageir-156:after {
  width: 7.02%;
}

.percentageir-157:after {
  width: 7.065%;
}

.percentageir-158:after {
  width: 7.11%;
}

.percentageir-159:after {
  width: 7.155%;
}

.percentageir-160:after {
  width: 7.2%;
}

.percentageir-161:after {
  width: 7.245%;
}

.percentageir-162:after {
  width: 7.29%;
}

.percentageir-163:after {
  width: 7.335%;
}

.percentageir-164:after {
  width: 7.38%;
}

.percentageir-165:after {
  width: 7.425%;
}

.percentageir-166:after {
  width: 7.47%;
}

.percentageir-167:after {
  width: 7.515%;
}

.percentageir-168:after {
  width: 7.56%;
}

.percentageir-169:after {
  width: 7.605%;
}

.percentageir-170:after {
  width: 7.65%;
}

.percentageir-171:after {
  width: 7.695%;
}

.percentageir-172:after {
  width: 7.74%;
}

.percentageir-173:after {
  width: 7.785%;
}

.percentageir-174:after {
  width: 7.83%;
}

.percentageir-175:after {
  width: 7.875%;
}

.percentageir-176:after {
  width: 7.92%;
}

.percentageir-177:after {
  width: 7.965%;
}

.percentageir-178:after {
  width: 8.01%;
}

.percentageir-179:after {
  width: 8.055%;
}

.percentageir-180:after {
  width: 8.1%;
}

.percentageir-181:after {
  width: 8.145%;
}

.percentageir-182:after {
  width: 8.19%;
}

.percentageir-183:after {
  width: 8.235%;
}

.percentageir-184:after {
  width: 8.28%;
}

.percentageir-185:after {
  width: 8.325%;
}

.percentageir-186:after {
  width: 8.37%;
}

.percentageir-187:after {
  width: 8.415%;
}

.percentageir-188:after {
  width: 8.46%;
}

.percentageir-189:after {
  width: 8.505%;
}

.percentageir-190:after {
  width: 8.55%;
}

.percentageir-191:after {
  width: 8.595%;
}

.percentageir-192:after {
  width: 8.64%;
}

.percentageir-193:after {
  width: 8.685%;
}

.percentageir-194:after {
  width: 8.73%;
}

.percentageir-195:after {
  width: 8.775%;
}

.percentageir-196:after {
  width: 8.82%;
}

.percentageir-197:after {
  width: 8.865%;
}

.percentageir-198:after {
  width: 8.91%;
}

.percentageir-199:after {
  width: 8.955%;
}

.percentageir-200:after {
  width: 9%;
}

.percentageir-201:after {
  width: 9.045%;
}

.percentageir-202:after {
  width: 9.09%;
}

.percentageir-203:after {
  width: 9.135%;
}

.percentageir-204:after {
  width: 9.18%;
}

.percentageir-205:after {
  width: 9.225%;
}

.percentageir-206:after {
  width: 9.27%;
}

.percentageir-207:after {
  width: 9.315%;
}

.percentageir-208:after {
  width: 9.36%;
}

.percentageir-209:after {
  width: 9.405%;
}

.percentageir-210:after {
  width: 9.45%;
}

.percentageir-211:after {
  width: 9.495%;
}

.percentageir-212:after {
  width: 9.54%;
}

.percentageir-213:after {
  width: 9.585%;
}

.percentageir-214:after {
  width: 9.63%;
}

.percentageir-215:after {
  width: 9.675%;
}

.percentageir-216:after {
  width: 9.72%;
}

.percentageir-217:after {
  width: 9.765%;
}

.percentageir-218:after {
  width: 9.81%;
}

.percentageir-219:after {
  width: 9.855%;
}

.percentageir-220:after {
  width: 9.9%;
}

.percentageir-221:after {
  width: 9.945%;
}

.percentageir-222:after {
  width: 9.99%;
}

.percentageir-223:after {
  width: 10.035%;
}

.percentageir-224:after {
  width: 10.08%;
}

.percentageir-225:after {
  width: 10.125%;
}

.percentageir-226:after {
  width: 10.17%;
}

.percentageir-227:after {
  width: 10.215%;
}

.percentageir-228:after {
  width: 10.26%;
}

.percentageir-229:after {
  width: 10.305%;
}

.percentageir-230:after {
  width: 10.35%;
}

.percentageir-231:after {
  width: 10.395%;
}

.percentageir-232:after {
  width: 10.44%;
}

.percentageir-233:after {
  width: 10.485%;
}

.percentageir-234:after {
  width: 10.53%;
}

.percentageir-235:after {
  width: 10.575%;
}

.percentageir-236:after {
  width: 10.62%;
}

.percentageir-237:after {
  width: 10.665%;
}

.percentageir-238:after {
  width: 10.71%;
}

.percentageir-239:after {
  width: 10.755%;
}

.percentageir-240:after {
  width: 10.8%;
}

.percentageir-241:after {
  width: 10.845%;
}

.percentageir-242:after {
  width: 10.89%;
}

.percentageir-243:after {
  width: 10.935%;
}

.percentageir-244:after {
  width: 10.98%;
}

.percentageir-245:after {
  width: 11.025%;
}

.percentageir-246:after {
  width: 11.07%;
}

.percentageir-247:after {
  width: 11.115%;
}

.percentageir-248:after {
  width: 11.16%;
}

.percentageir-249:after {
  width: 11.205%;
}

.percentageir-250:after {
  width: 11.25%;
}

.percentageir-251:after {
  width: 11.295%;
}

.percentageir-252:after {
  width: 11.34%;
}

.percentageir-253:after {
  width: 11.385%;
}

.percentageir-254:after {
  width: 11.43%;
}

.percentageir-255:after {
  width: 11.475%;
}

.percentageir-256:after {
  width: 11.52%;
}

.percentageir-257:after {
  width: 11.565%;
}

.percentageir-258:after {
  width: 11.61%;
}

.percentageir-259:after {
  width: 11.655%;
}

.percentageir-260:after {
  width: 11.7%;
}

.percentageir-261:after {
  width: 11.745%;
}

.percentageir-262:after {
  width: 11.79%;
}

.percentageir-263:after {
  width: 11.835%;
}

.percentageir-264:after {
  width: 11.88%;
}

.percentageir-265:after {
  width: 11.925%;
}

.percentageir-266:after {
  width: 11.97%;
}

.percentageir-267:after {
  width: 12.015%;
}

.percentageir-268:after {
  width: 12.06%;
}

.percentageir-269:after {
  width: 12.105%;
}

.percentageir-270:after {
  width: 12.15%;
}

.percentageir-271:after {
  width: 12.195%;
}

.percentageir-272:after {
  width: 12.24%;
}

.percentageir-273:after {
  width: 12.285%;
}

.percentageir-274:after {
  width: 12.33%;
}

.percentageir-275:after {
  width: 12.375%;
}

.percentageir-276:after {
  width: 12.42%;
}

.percentageir-277:after {
  width: 12.465%;
}

.percentageir-278:after {
  width: 12.51%;
}

.percentageir-279:after {
  width: 12.555%;
}

.percentageir-280:after {
  width: 12.6%;
}

.percentageir-281:after {
  width: 12.645%;
}

.percentageir-282:after {
  width: 12.69%;
}

.percentageir-283:after {
  width: 12.735%;
}

.percentageir-284:after {
  width: 12.78%;
}

.percentageir-285:after {
  width: 12.825%;
}

.percentageir-286:after {
  width: 12.87%;
}

.percentageir-287:after {
  width: 12.915%;
}

.percentageir-288:after {
  width: 12.96%;
}

.percentageir-289:after {
  width: 13.005%;
}

.percentageir-290:after {
  width: 13.05%;
}

.percentageir-291:after {
  width: 13.095%;
}

.percentageir-292:after {
  width: 13.14%;
}

.percentageir-293:after {
  width: 13.185%;
}

.percentageir-294:after {
  width: 13.23%;
}

.percentageir-295:after {
  width: 13.275%;
}

.percentageir-296:after {
  width: 13.32%;
}

.percentageir-297:after {
  width: 13.365%;
}

.percentageir-298:after {
  width: 13.41%;
}

.percentageir-299:after {
  width: 13.455%;
}

.percentageir-300:after {
  width: 13.5%;
}

.percentageir-301:after {
  width: 13.545%;
}

.percentageir-302:after {
  width: 13.59%;
}

.percentageir-303:after {
  width: 13.635%;
}

.percentageir-304:after {
  width: 13.68%;
}

.percentageir-305:after {
  width: 13.725%;
}

.percentageir-306:after {
  width: 13.77%;
}

.percentageir-307:after {
  width: 13.815%;
}

.percentageir-308:after {
  width: 13.86%;
}

.percentageir-309:after {
  width: 13.905%;
}

.percentageir-310:after {
  width: 13.95%;
}

.percentageir-311:after {
  width: 13.995%;
}

.percentageir-312:after {
  width: 14.04%;
}

.percentageir-313:after {
  width: 14.085%;
}

.percentageir-314:after {
  width: 14.13%;
}

.percentageir-315:after {
  width: 14.175%;
}

.percentageir-316:after {
  width: 14.22%;
}

.percentageir-317:after {
  width: 14.265%;
}

.percentageir-318:after {
  width: 14.31%;
}

.percentageir-319:after {
  width: 14.355%;
}

.percentageir-320:after {
  width: 14.4%;
}

.percentageir-321:after {
  width: 14.445%;
}

.percentageir-322:after {
  width: 14.49%;
}

.percentageir-323:after {
  width: 14.535%;
}

.percentageir-324:after {
  width: 14.58%;
}

.percentageir-325:after {
  width: 14.625%;
}

.percentageir-326:after {
  width: 14.67%;
}

.percentageir-327:after {
  width: 14.715%;
}

.percentageir-328:after {
  width: 14.76%;
}

.percentageir-329:after {
  width: 14.805%;
}

.percentageir-330:after {
  width: 14.85%;
}

.percentageir-331:after {
  width: 14.895%;
}

.percentageir-332:after {
  width: 14.94%;
}

.percentageir-333:after {
  width: 14.985%;
}

.percentageir-334:after {
  width: 15.03%;
}

.percentageir-335:after {
  width: 15.075%;
}

.percentageir-336:after {
  width: 15.12%;
}

.percentageir-337:after {
  width: 15.165%;
}

.percentageir-338:after {
  width: 15.21%;
}

.percentageir-339:after {
  width: 15.255%;
}

.percentageir-340:after {
  width: 15.3%;
}

.percentageir-341:after {
  width: 15.345%;
}

.percentageir-342:after {
  width: 15.39%;
}

.percentageir-343:after {
  width: 15.435%;
}

.percentageir-344:after {
  width: 15.48%;
}

.percentageir-345:after {
  width: 15.525%;
}

.percentageir-346:after {
  width: 15.57%;
}

.percentageir-347:after {
  width: 15.615%;
}

.percentageir-348:after {
  width: 15.66%;
}

.percentageir-349:after {
  width: 15.705%;
}

.percentageir-350:after {
  width: 15.75%;
}

.percentageir-351:after {
  width: 15.795%;
}

.percentageir-352:after {
  width: 15.84%;
}

.percentageir-353:after {
  width: 15.885%;
}

.percentageir-354:after {
  width: 15.93%;
}

.percentageir-355:after {
  width: 15.975%;
}

.percentageir-356:after {
  width: 16.02%;
}

.percentageir-357:after {
  width: 16.065%;
}

.percentageir-358:after {
  width: 16.11%;
}

.percentageir-359:after {
  width: 16.155%;
}

.percentageir-360:after {
  width: 16.2%;
}

.percentageir-361:after {
  width: 16.245%;
}

.percentageir-362:after {
  width: 16.29%;
}

.percentageir-363:after {
  width: 16.335%;
}

.percentageir-364:after {
  width: 16.38%;
}

.percentageir-365:after {
  width: 16.425%;
}

.percentageir-366:after {
  width: 16.47%;
}

.percentageir-367:after {
  width: 16.515%;
}

.percentageir-368:after {
  width: 16.56%;
}

.percentageir-369:after {
  width: 16.605%;
}

.percentageir-370:after {
  width: 16.65%;
}

.percentageir-371:after {
  width: 16.695%;
}

.percentageir-372:after {
  width: 16.74%;
}

.percentageir-373:after {
  width: 16.785%;
}

.percentageir-374:after {
  width: 16.83%;
}

.percentageir-375:after {
  width: 16.875%;
}

.percentageir-376:after {
  width: 16.92%;
}

.percentageir-377:after {
  width: 16.965%;
}

.percentageir-378:after {
  width: 17.01%;
}

.percentageir-379:after {
  width: 17.055%;
}

.percentageir-380:after {
  width: 17.1%;
}

.percentageir-381:after {
  width: 17.145%;
}

.percentageir-382:after {
  width: 17.19%;
}

.percentageir-383:after {
  width: 17.235%;
}

.percentageir-384:after {
  width: 17.28%;
}

.percentageir-385:after {
  width: 17.325%;
}

.percentageir-386:after {
  width: 17.37%;
}

.percentageir-387:after {
  width: 17.415%;
}

.percentageir-388:after {
  width: 17.46%;
}

.percentageir-389:after {
  width: 17.505%;
}

.percentageir-390:after {
  width: 17.55%;
}

.percentageir-391:after {
  width: 17.595%;
}

.percentageir-392:after {
  width: 17.64%;
}

.percentageir-393:after {
  width: 17.685%;
}

.percentageir-394:after {
  width: 17.73%;
}

.percentageir-395:after {
  width: 17.775%;
}

.percentageir-396:after {
  width: 17.82%;
}

.percentageir-397:after {
  width: 17.865%;
}

.percentageir-398:after {
  width: 17.91%;
}

.percentageir-399:after {
  width: 17.955%;
}

.percentageir-400:after {
  width: 18%;
}

.percentageir-401:after {
  width: 18.045%;
}

.percentageir-402:after {
  width: 18.09%;
}

.percentageir-403:after {
  width: 18.135%;
}

.percentageir-404:after {
  width: 18.18%;
}

.percentageir-405:after {
  width: 18.225%;
}

.percentageir-406:after {
  width: 18.27%;
}

.percentageir-407:after {
  width: 18.315%;
}

.percentageir-408:after {
  width: 18.36%;
}

.percentageir-409:after {
  width: 18.405%;
}

.percentageir-410:after {
  width: 18.45%;
}

.percentageir-411:after {
  width: 18.495%;
}

.percentageir-412:after {
  width: 18.54%;
}

.percentageir-413:after {
  width: 18.585%;
}

.percentageir-414:after {
  width: 18.63%;
}

.percentageir-415:after {
  width: 18.675%;
}

.percentageir-416:after {
  width: 18.72%;
}

.percentageir-417:after {
  width: 18.765%;
}

.percentageir-418:after {
  width: 18.81%;
}

.percentageir-419:after {
  width: 18.855%;
}

.percentageir-420:after {
  width: 18.9%;
}

.percentageir-421:after {
  width: 18.945%;
}

.percentageir-422:after {
  width: 18.99%;
}

.percentageir-423:after {
  width: 19.035%;
}

.percentageir-424:after {
  width: 19.08%;
}

.percentageir-425:after {
  width: 19.125%;
}

.percentageir-426:after {
  width: 19.17%;
}

.percentageir-427:after {
  width: 19.215%;
}

.percentageir-428:after {
  width: 19.26%;
}

.percentageir-429:after {
  width: 19.305%;
}

.percentageir-430:after {
  width: 19.35%;
}

.percentageir-431:after {
  width: 19.395%;
}

.percentageir-432:after {
  width: 19.44%;
}

.percentageir-433:after {
  width: 19.485%;
}

.percentageir-434:after {
  width: 19.53%;
}

.percentageir-435:after {
  width: 19.575%;
}

.percentageir-436:after {
  width: 19.62%;
}

.percentageir-437:after {
  width: 19.665%;
}

.percentageir-438:after {
  width: 19.71%;
}

.percentageir-439:after {
  width: 19.755%;
}

.percentageir-440:after {
  width: 19.8%;
}

.percentageir-441:after {
  width: 19.845%;
}

.percentageir-442:after {
  width: 19.89%;
}

.percentageir-443:after {
  width: 19.935%;
}

.percentageir-444:after {
  width: 19.98%;
}

.percentageir-445:after {
  width: 20.025%;
}

.percentageir-446:after {
  width: 20.07%;
}

.percentageir-447:after {
  width: 20.115%;
}

.percentageir-448:after {
  width: 20.16%;
}

.percentageir-449:after {
  width: 20.205%;
}

.percentageir-450:after {
  width: 20.25%;
}

.percentageir-451:after {
  width: 20.295%;
}

.percentageir-452:after {
  width: 20.34%;
}

.percentageir-453:after {
  width: 20.385%;
}

.percentageir-454:after {
  width: 20.43%;
}

.percentageir-455:after {
  width: 20.475%;
}

.percentageir-456:after {
  width: 20.52%;
}

.percentageir-457:after {
  width: 20.565%;
}

.percentageir-458:after {
  width: 20.61%;
}

.percentageir-459:after {
  width: 20.655%;
}

.percentageir-460:after {
  width: 20.7%;
}

.percentageir-461:after {
  width: 20.745%;
}

.percentageir-462:after {
  width: 20.79%;
}

.percentageir-463:after {
  width: 20.835%;
}

.percentageir-464:after {
  width: 20.88%;
}

.percentageir-465:after {
  width: 20.925%;
}

.percentageir-466:after {
  width: 20.97%;
}

.percentageir-467:after {
  width: 21.015%;
}

.percentageir-468:after {
  width: 21.06%;
}

.percentageir-469:after {
  width: 21.105%;
}

.percentageir-470:after {
  width: 21.15%;
}

.percentageir-471:after {
  width: 21.195%;
}

.percentageir-472:after {
  width: 21.24%;
}

.percentageir-473:after {
  width: 21.285%;
}

.percentageir-474:after {
  width: 21.33%;
}

.percentageir-475:after {
  width: 21.375%;
}

.percentageir-476:after {
  width: 21.42%;
}

.percentageir-477:after {
  width: 21.465%;
}

.percentageir-478:after {
  width: 21.51%;
}

.percentageir-479:after {
  width: 21.555%;
}

.percentageir-480:after {
  width: 21.6%;
}

.percentageir-481:after {
  width: 21.645%;
}

.percentageir-482:after {
  width: 21.69%;
}

.percentageir-483:after {
  width: 21.735%;
}

.percentageir-484:after {
  width: 21.78%;
}

.percentageir-485:after {
  width: 21.825%;
}

.percentageir-486:after {
  width: 21.87%;
}

.percentageir-487:after {
  width: 21.915%;
}

.percentageir-488:after {
  width: 21.96%;
}

.percentageir-489:after {
  width: 22.005%;
}

.percentageir-490:after {
  width: 22.05%;
}

.percentageir-491:after {
  width: 22.095%;
}

.percentageir-492:after {
  width: 22.14%;
}

.percentageir-493:after {
  width: 22.185%;
}

.percentageir-494:after {
  width: 22.23%;
}

.percentageir-495:after {
  width: 22.275%;
}

.percentageir-496:after {
  width: 22.32%;
}

.percentageir-497:after {
  width: 22.365%;
}

.percentageir-498:after {
  width: 22.41%;
}

.percentageir-499:after {
  width: 22.455%;
}

.percentageir-500:after {
  width: 22.5%;
}

.percentageir-501:after {
  width: 22.545%;
}

.percentageir-502:after {
  width: 22.59%;
}

.percentageir-503:after {
  width: 22.635%;
}

.percentageir-504:after {
  width: 22.68%;
}

.percentageir-505:after {
  width: 22.725%;
}

.percentageir-506:after {
  width: 22.77%;
}

.percentageir-507:after {
  width: 22.815%;
}

.percentageir-508:after {
  width: 22.86%;
}

.percentageir-509:after {
  width: 22.905%;
}

.percentageir-510:after {
  width: 22.95%;
}

.percentageir-511:after {
  width: 22.995%;
}

.percentageir-512:after {
  width: 23.04%;
}

.percentageir-513:after {
  width: 23.085%;
}

.percentageir-514:after {
  width: 23.13%;
}

.percentageir-515:after {
  width: 23.175%;
}

.percentageir-516:after {
  width: 23.22%;
}

.percentageir-517:after {
  width: 23.265%;
}

.percentageir-518:after {
  width: 23.31%;
}

.percentageir-519:after {
  width: 23.355%;
}

.percentageir-520:after {
  width: 23.4%;
}

.percentageir-521:after {
  width: 23.445%;
}

.percentageir-522:after {
  width: 23.49%;
}

.percentageir-523:after {
  width: 23.535%;
}

.percentageir-524:after {
  width: 23.58%;
}

.percentageir-525:after {
  width: 23.625%;
}

.percentageir-526:after {
  width: 23.67%;
}

.percentageir-527:after {
  width: 23.715%;
}

.percentageir-528:after {
  width: 23.76%;
}

.percentageir-529:after {
  width: 23.805%;
}

.percentageir-530:after {
  width: 23.85%;
}

.percentageir-531:after {
  width: 23.895%;
}

.percentageir-532:after {
  width: 23.94%;
}

.percentageir-533:after {
  width: 23.985%;
}

.percentageir-534:after {
  width: 24.03%;
}

.percentageir-535:after {
  width: 24.075%;
}

.percentageir-536:after {
  width: 24.12%;
}

.percentageir-537:after {
  width: 24.165%;
}

.percentageir-538:after {
  width: 24.21%;
}

.percentageir-539:after {
  width: 24.255%;
}

.percentageir-540:after {
  width: 24.3%;
}

.percentageir-541:after {
  width: 24.345%;
}

.percentageir-542:after {
  width: 24.39%;
}

.percentageir-543:after {
  width: 24.435%;
}

.percentageir-544:after {
  width: 24.48%;
}

.percentageir-545:after {
  width: 24.525%;
}

.percentageir-546:after {
  width: 24.57%;
}

.percentageir-547:after {
  width: 24.615%;
}

.percentageir-548:after {
  width: 24.66%;
}

.percentageir-549:after {
  width: 24.705%;
}

.percentageir-550:after {
  width: 24.75%;
}

.percentageir-551:after {
  width: 24.795%;
}

.percentageir-552:after {
  width: 24.84%;
}

.percentageir-553:after {
  width: 24.885%;
}

.percentageir-554:after {
  width: 24.93%;
}

.percentageir-555:after {
  width: 24.975%;
}

.percentageir-556:after {
  width: 25.02%;
}

.percentageir-557:after {
  width: 25.065%;
}

.percentageir-558:after {
  width: 25.11%;
}

.percentageir-559:after {
  width: 25.155%;
}

.percentageir-560:after {
  width: 25.2%;
}

.percentageir-561:after {
  width: 25.245%;
}

.percentageir-562:after {
  width: 25.29%;
}

.percentageir-563:after {
  width: 25.335%;
}

.percentageir-564:after {
  width: 25.38%;
}

.percentageir-565:after {
  width: 25.425%;
}

.percentageir-566:after {
  width: 25.47%;
}

.percentageir-567:after {
  width: 25.515%;
}

.percentageir-568:after {
  width: 25.56%;
}

.percentageir-569:after {
  width: 25.605%;
}

.percentageir-570:after {
  width: 25.65%;
}

.percentageir-571:after {
  width: 25.695%;
}

.percentageir-572:after {
  width: 25.74%;
}

.percentageir-573:after {
  width: 25.785%;
}

.percentageir-574:after {
  width: 25.83%;
}

.percentageir-575:after {
  width: 25.875%;
}

.percentageir-576:after {
  width: 25.92%;
}

.percentageir-577:after {
  width: 25.965%;
}

.percentageir-578:after {
  width: 26.01%;
}

.percentageir-579:after {
  width: 26.055%;
}

.percentageir-580:after {
  width: 26.1%;
}

.percentageir-581:after {
  width: 26.145%;
}

.percentageir-582:after {
  width: 26.19%;
}

.percentageir-583:after {
  width: 26.235%;
}

.percentageir-584:after {
  width: 26.28%;
}

.percentageir-585:after {
  width: 26.325%;
}

.percentageir-586:after {
  width: 26.37%;
}

.percentageir-587:after {
  width: 26.415%;
}

.percentageir-588:after {
  width: 26.46%;
}

.percentageir-589:after {
  width: 26.505%;
}

.percentageir-590:after {
  width: 26.55%;
}

.percentageir-591:after {
  width: 26.595%;
}

.percentageir-592:after {
  width: 26.64%;
}

.percentageir-593:after {
  width: 26.685%;
}

.percentageir-594:after {
  width: 26.73%;
}

.percentageir-595:after {
  width: 26.775%;
}

.percentageir-596:after {
  width: 26.82%;
}

.percentageir-597:after {
  width: 26.865%;
}

.percentageir-598:after {
  width: 26.91%;
}

.percentageir-599:after {
  width: 26.955%;
}

.percentageir-600:after {
  width: 27%;
}

.percentageir-601:after {
  width: 27.045%;
}

.percentageir-602:after {
  width: 27.09%;
}

.percentageir-603:after {
  width: 27.135%;
}

.percentageir-604:after {
  width: 27.18%;
}

.percentageir-605:after {
  width: 27.225%;
}

.percentageir-606:after {
  width: 27.27%;
}

.percentageir-607:after {
  width: 27.315%;
}

.percentageir-608:after {
  width: 27.36%;
}

.percentageir-609:after {
  width: 27.405%;
}

.percentageir-610:after {
  width: 27.45%;
}

.percentageir-611:after {
  width: 27.495%;
}

.percentageir-612:after {
  width: 27.54%;
}

.percentageir-613:after {
  width: 27.585%;
}

.percentageir-614:after {
  width: 27.63%;
}

.percentageir-615:after {
  width: 27.675%;
}

.percentageir-616:after {
  width: 27.72%;
}

.percentageir-617:after {
  width: 27.765%;
}

.percentageir-618:after {
  width: 27.81%;
}

.percentageir-619:after {
  width: 27.855%;
}

.percentageir-620:after {
  width: 27.9%;
}

.percentageir-621:after {
  width: 27.945%;
}

.percentageir-622:after {
  width: 27.99%;
}

.percentageir-623:after {
  width: 28.035%;
}

.percentageir-624:after {
  width: 28.08%;
}

.percentageir-625:after {
  width: 28.125%;
}

.percentageir-626:after {
  width: 28.17%;
}

.percentageir-627:after {
  width: 28.215%;
}

.percentageir-628:after {
  width: 28.26%;
}

.percentageir-629:after {
  width: 28.305%;
}

.percentageir-630:after {
  width: 28.35%;
}

.percentageir-631:after {
  width: 28.395%;
}

.percentageir-632:after {
  width: 28.44%;
}

.percentageir-633:after {
  width: 28.485%;
}

.percentageir-634:after {
  width: 28.53%;
}

.percentageir-635:after {
  width: 28.575%;
}

.percentageir-636:after {
  width: 28.62%;
}

.percentageir-637:after {
  width: 28.665%;
}

.percentageir-638:after {
  width: 28.71%;
}

.percentageir-639:after {
  width: 28.755%;
}

.percentageir-640:after {
  width: 28.8%;
}

.percentageir-641:after {
  width: 28.845%;
}

.percentageir-642:after {
  width: 28.89%;
}

.percentageir-643:after {
  width: 28.935%;
}

.percentageir-644:after {
  width: 28.98%;
}

.percentageir-645:after {
  width: 29.025%;
}

.percentageir-646:after {
  width: 29.07%;
}

.percentageir-647:after {
  width: 29.115%;
}

.percentageir-648:after {
  width: 29.16%;
}

.percentageir-649:after {
  width: 29.205%;
}

.percentageir-650:after {
  width: 29.25%;
}

.percentageir-651:after {
  width: 29.295%;
}

.percentageir-652:after {
  width: 29.34%;
}

.percentageir-653:after {
  width: 29.385%;
}

.percentageir-654:after {
  width: 29.43%;
}

.percentageir-655:after {
  width: 29.475%;
}

.percentageir-656:after {
  width: 29.52%;
}

.percentageir-657:after {
  width: 29.565%;
}

.percentageir-658:after {
  width: 29.61%;
}

.percentageir-659:after {
  width: 29.655%;
}

.percentageir-660:after {
  width: 29.7%;
}

.percentageir-661:after {
  width: 29.745%;
}

.percentageir-662:after {
  width: 29.79%;
}

.percentageir-663:after {
  width: 29.835%;
}

.percentageir-664:after {
  width: 29.88%;
}

.percentageir-665:after {
  width: 29.925%;
}

.percentageir-666:after {
  width: 29.97%;
}

.percentageir-667:after {
  width: 30.015%;
}

.percentageir-668:after {
  width: 30.06%;
}

.percentageir-669:after {
  width: 30.105%;
}

.percentageir-670:after {
  width: 30.15%;
}

.percentageir-671:after {
  width: 30.195%;
}

.percentageir-672:after {
  width: 30.24%;
}

.percentageir-673:after {
  width: 30.285%;
}

.percentageir-674:after {
  width: 30.33%;
}

.percentageir-675:after {
  width: 30.375%;
}

.percentageir-676:after {
  width: 30.42%;
}

.percentageir-677:after {
  width: 30.465%;
}

.percentageir-678:after {
  width: 30.51%;
}

.percentageir-679:after {
  width: 30.555%;
}

.percentageir-680:after {
  width: 30.6%;
}

.percentageir-681:after {
  width: 30.645%;
}

.percentageir-682:after {
  width: 30.69%;
}

.percentageir-683:after {
  width: 30.735%;
}

.percentageir-684:after {
  width: 30.78%;
}

.percentageir-685:after {
  width: 30.825%;
}

.percentageir-686:after {
  width: 30.87%;
}

.percentageir-687:after {
  width: 30.915%;
}

.percentageir-688:after {
  width: 30.96%;
}

.percentageir-689:after {
  width: 31.005%;
}

.percentageir-690:after {
  width: 31.05%;
}

.percentageir-691:after {
  width: 31.095%;
}

.percentageir-692:after {
  width: 31.14%;
}

.percentageir-693:after {
  width: 31.185%;
}

.percentageir-694:after {
  width: 31.23%;
}

.percentageir-695:after {
  width: 31.275%;
}

.percentageir-696:after {
  width: 31.32%;
}

.percentageir-697:after {
  width: 31.365%;
}

.percentageir-698:after {
  width: 31.41%;
}

.percentageir-699:after {
  width: 31.455%;
}

.percentageir-700:after {
  width: 31.5%;
}

.percentageir-701:after {
  width: 31.545%;
}

.percentageir-702:after {
  width: 31.59%;
}

.percentageir-703:after {
  width: 31.635%;
}

.percentageir-704:after {
  width: 31.68%;
}

.percentageir-705:after {
  width: 31.725%;
}

.percentageir-706:after {
  width: 31.77%;
}

.percentageir-707:after {
  width: 31.815%;
}

.percentageir-708:after {
  width: 31.86%;
}

.percentageir-709:after {
  width: 31.905%;
}

.percentageir-710:after {
  width: 31.95%;
}

.percentageir-711:after {
  width: 31.995%;
}

.percentageir-712:after {
  width: 32.04%;
}

.percentageir-713:after {
  width: 32.085%;
}

.percentageir-714:after {
  width: 32.13%;
}

.percentageir-715:after {
  width: 32.175%;
}

.percentageir-716:after {
  width: 32.22%;
}

.percentageir-717:after {
  width: 32.265%;
}

.percentageir-718:after {
  width: 32.31%;
}

.percentageir-719:after {
  width: 32.355%;
}

.percentageir-720:after {
  width: 32.4%;
}

.percentageir-721:after {
  width: 32.445%;
}

.percentageir-722:after {
  width: 32.49%;
}

.percentageir-723:after {
  width: 32.535%;
}

.percentageir-724:after {
  width: 32.58%;
}

.percentageir-725:after {
  width: 32.625%;
}

.percentageir-726:after {
  width: 32.67%;
}

.percentageir-727:after {
  width: 32.715%;
}

.percentageir-728:after {
  width: 32.76%;
}

.percentageir-729:after {
  width: 32.805%;
}

.percentageir-730:after {
  width: 32.85%;
}

.percentageir-731:after {
  width: 32.895%;
}

.percentageir-732:after {
  width: 32.94%;
}

.percentageir-733:after {
  width: 32.985%;
}

.percentageir-734:after {
  width: 33.03%;
}

.percentageir-735:after {
  width: 33.075%;
}

.percentageir-736:after {
  width: 33.12%;
}

.percentageir-737:after {
  width: 33.165%;
}

.percentageir-738:after {
  width: 33.21%;
}

.percentageir-739:after {
  width: 33.255%;
}

.percentageir-740:after {
  width: 33.3%;
}

.percentageir-741:after {
  width: 33.345%;
}

.percentageir-742:after {
  width: 33.39%;
}

.percentageir-743:after {
  width: 33.435%;
}

.percentageir-744:after {
  width: 33.48%;
}

.percentageir-745:after {
  width: 33.525%;
}

.percentageir-746:after {
  width: 33.57%;
}

.percentageir-747:after {
  width: 33.615%;
}

.percentageir-748:after {
  width: 33.66%;
}

.percentageir-749:after {
  width: 33.705%;
}

.percentageir-750:after {
  width: 33.75%;
}

.percentageir-751:after {
  width: 33.795%;
}

.percentageir-752:after {
  width: 33.84%;
}

.percentageir-753:after {
  width: 33.885%;
}

.percentageir-754:after {
  width: 33.93%;
}

.percentageir-755:after {
  width: 33.975%;
}

.percentageir-756:after {
  width: 34.02%;
}

.percentageir-757:after {
  width: 34.065%;
}

.percentageir-758:after {
  width: 34.11%;
}

.percentageir-759:after {
  width: 34.155%;
}

.percentageir-760:after {
  width: 34.2%;
}

.percentageir-761:after {
  width: 34.245%;
}

.percentageir-762:after {
  width: 34.29%;
}

.percentageir-763:after {
  width: 34.335%;
}

.percentageir-764:after {
  width: 34.38%;
}

.percentageir-765:after {
  width: 34.425%;
}

.percentageir-766:after {
  width: 34.47%;
}

.percentageir-767:after {
  width: 34.515%;
}

.percentageir-768:after {
  width: 34.56%;
}

.percentageir-769:after {
  width: 34.605%;
}

.percentageir-770:after {
  width: 34.65%;
}

.percentageir-771:after {
  width: 34.695%;
}

.percentageir-772:after {
  width: 34.74%;
}

.percentageir-773:after {
  width: 34.785%;
}

.percentageir-774:after {
  width: 34.83%;
}

.percentageir-775:after {
  width: 34.875%;
}

.percentageir-776:after {
  width: 34.92%;
}

.percentageir-777:after {
  width: 34.965%;
}

.percentageir-778:after {
  width: 35.01%;
}

.percentageir-779:after {
  width: 35.055%;
}

.percentageir-780:after {
  width: 35.1%;
}

.percentageir-781:after {
  width: 35.145%;
}

.percentageir-782:after {
  width: 35.19%;
}

.percentageir-783:after {
  width: 35.235%;
}

.percentageir-784:after {
  width: 35.28%;
}

.percentageir-785:after {
  width: 35.325%;
}

.percentageir-786:after {
  width: 35.37%;
}

.percentageir-787:after {
  width: 35.415%;
}

.percentageir-788:after {
  width: 35.46%;
}

.percentageir-789:after {
  width: 35.505%;
}

.percentageir-790:after {
  width: 35.55%;
}

.percentageir-791:after {
  width: 35.595%;
}

.percentageir-792:after {
  width: 35.64%;
}

.percentageir-793:after {
  width: 35.685%;
}

.percentageir-794:after {
  width: 35.73%;
}

.percentageir-795:after {
  width: 35.775%;
}

.percentageir-796:after {
  width: 35.82%;
}

.percentageir-797:after {
  width: 35.865%;
}

.percentageir-798:after {
  width: 35.91%;
}

.percentageir-799:after {
  width: 35.955%;
}

.percentageir-800:after {
  width: 36%;
}

.percentageir-801:after {
  width: 36.045%;
}

.percentageir-802:after {
  width: 36.09%;
}

.percentageir-803:after {
  width: 36.135%;
}

.percentageir-804:after {
  width: 36.18%;
}

.percentageir-805:after {
  width: 36.225%;
}

.percentageir-806:after {
  width: 36.27%;
}

.percentageir-807:after {
  width: 36.315%;
}

.percentageir-808:after {
  width: 36.36%;
}

.percentageir-809:after {
  width: 36.405%;
}

.percentageir-810:after {
  width: 36.45%;
}

.percentageir-811:after {
  width: 36.495%;
}

.percentageir-812:after {
  width: 36.54%;
}

.percentageir-813:after {
  width: 36.585%;
}

.percentageir-814:after {
  width: 36.63%;
}

.percentageir-815:after {
  width: 36.675%;
}

.percentageir-816:after {
  width: 36.72%;
}

.percentageir-817:after {
  width: 36.765%;
}

.percentageir-818:after {
  width: 36.81%;
}

.percentageir-819:after {
  width: 36.855%;
}

.percentageir-820:after {
  width: 36.9%;
}

.percentageir-821:after {
  width: 36.945%;
}

.percentageir-822:after {
  width: 36.99%;
}

.percentageir-823:after {
  width: 37.035%;
}

.percentageir-824:after {
  width: 37.08%;
}

.percentageir-825:after {
  width: 37.125%;
}

.percentageir-826:after {
  width: 37.17%;
}

.percentageir-827:after {
  width: 37.215%;
}

.percentageir-828:after {
  width: 37.26%;
}

.percentageir-829:after {
  width: 37.305%;
}

.percentageir-830:after {
  width: 37.35%;
}

.percentageir-831:after {
  width: 37.395%;
}

.percentageir-832:after {
  width: 37.44%;
}

.percentageir-833:after {
  width: 37.485%;
}

.percentageir-834:after {
  width: 37.53%;
}

.percentageir-835:after {
  width: 37.575%;
}

.percentageir-836:after {
  width: 37.62%;
}

.percentageir-837:after {
  width: 37.665%;
}

.percentageir-838:after {
  width: 37.71%;
}

.percentageir-839:after {
  width: 37.755%;
}

.percentageir-840:after {
  width: 37.8%;
}

.percentageir-841:after {
  width: 37.845%;
}

.percentageir-842:after {
  width: 37.89%;
}

.percentageir-843:after {
  width: 37.935%;
}

.percentageir-844:after {
  width: 37.98%;
}

.percentageir-845:after {
  width: 38.025%;
}

.percentageir-846:after {
  width: 38.07%;
}

.percentageir-847:after {
  width: 38.115%;
}

.percentageir-848:after {
  width: 38.16%;
}

.percentageir-849:after {
  width: 38.205%;
}

.percentageir-850:after {
  width: 38.25%;
}

.percentageir-851:after {
  width: 38.295%;
}

.percentageir-852:after {
  width: 38.34%;
}

.percentageir-853:after {
  width: 38.385%;
}

.percentageir-854:after {
  width: 38.43%;
}

.percentageir-855:after {
  width: 38.475%;
}

.percentageir-856:after {
  width: 38.52%;
}

.percentageir-857:after {
  width: 38.565%;
}

.percentageir-858:after {
  width: 38.61%;
}

.percentageir-859:after {
  width: 38.655%;
}

.percentageir-860:after {
  width: 38.7%;
}

.percentageir-861:after {
  width: 38.745%;
}

.percentageir-862:after {
  width: 38.79%;
}

.percentageir-863:after {
  width: 38.835%;
}

.percentageir-864:after {
  width: 38.88%;
}

.percentageir-865:after {
  width: 38.925%;
}

.percentageir-866:after {
  width: 38.97%;
}

.percentageir-867:after {
  width: 39.015%;
}

.percentageir-868:after {
  width: 39.06%;
}

.percentageir-869:after {
  width: 39.105%;
}

.percentageir-870:after {
  width: 39.15%;
}

.percentageir-871:after {
  width: 39.195%;
}

.percentageir-872:after {
  width: 39.24%;
}

.percentageir-873:after {
  width: 39.285%;
}

.percentageir-874:after {
  width: 39.33%;
}

.percentageir-875:after {
  width: 39.375%;
}

.percentageir-876:after {
  width: 39.42%;
}

.percentageir-877:after {
  width: 39.465%;
}

.percentageir-878:after {
  width: 39.51%;
}

.percentageir-879:after {
  width: 39.555%;
}

.percentageir-880:after {
  width: 39.6%;
}

.percentageir-881:after {
  width: 39.645%;
}

.percentageir-882:after {
  width: 39.69%;
}

.percentageir-883:after {
  width: 39.735%;
}

.percentageir-884:after {
  width: 39.78%;
}

.percentageir-885:after {
  width: 39.825%;
}

.percentageir-886:after {
  width: 39.87%;
}

.percentageir-887:after {
  width: 39.915%;
}

.percentageir-888:after {
  width: 39.96%;
}

.percentageir-889:after {
  width: 40.005%;
}

.percentageir-890:after {
  width: 40.05%;
}

.percentageir-891:after {
  width: 40.095%;
}

.percentageir-892:after {
  width: 40.14%;
}

.percentageir-893:after {
  width: 40.185%;
}

.percentageir-894:after {
  width: 40.23%;
}

.percentageir-895:after {
  width: 40.275%;
}

.percentageir-896:after {
  width: 40.32%;
}

.percentageir-897:after {
  width: 40.365%;
}

.percentageir-898:after {
  width: 40.41%;
}

.percentageir-899:after {
  width: 40.455%;
}

.percentageir-900:after {
  width: 40.5%;
}

.percentageir-901:after {
  width: 40.545%;
}

.percentageir-902:after {
  width: 40.59%;
}

.percentageir-903:after {
  width: 40.635%;
}

.percentageir-904:after {
  width: 40.68%;
}

.percentageir-905:after {
  width: 40.725%;
}

.percentageir-906:after {
  width: 40.77%;
}

.percentageir-907:after {
  width: 40.815%;
}

.percentageir-908:after {
  width: 40.86%;
}

.percentageir-909:after {
  width: 40.905%;
}

.percentageir-910:after {
  width: 40.95%;
}

.percentageir-911:after {
  width: 40.995%;
}

.percentageir-912:after {
  width: 41.04%;
}

.percentageir-913:after {
  width: 41.085%;
}

.percentageir-914:after {
  width: 41.13%;
}

.percentageir-915:after {
  width: 41.175%;
}

.percentageir-916:after {
  width: 41.22%;
}

.percentageir-917:after {
  width: 41.265%;
}

.percentageir-918:after {
  width: 41.31%;
}

.percentageir-919:after {
  width: 41.355%;
}

.percentageir-920:after {
  width: 41.4%;
}

.percentageir-921:after {
  width: 41.445%;
}

.percentageir-922:after {
  width: 41.49%;
}

.percentageir-923:after {
  width: 41.535%;
}

.percentageir-924:after {
  width: 41.58%;
}

.percentageir-925:after {
  width: 41.625%;
}

.percentageir-926:after {
  width: 41.67%;
}

.percentageir-927:after {
  width: 41.715%;
}

.percentageir-928:after {
  width: 41.76%;
}

.percentageir-929:after {
  width: 41.805%;
}

.percentageir-930:after {
  width: 41.85%;
}

.percentageir-931:after {
  width: 41.895%;
}

.percentageir-932:after {
  width: 41.94%;
}

.percentageir-933:after {
  width: 41.985%;
}

.percentageir-934:after {
  width: 42.03%;
}

.percentageir-935:after {
  width: 42.075%;
}

.percentageir-936:after {
  width: 42.12%;
}

.percentageir-937:after {
  width: 42.165%;
}

.percentageir-938:after {
  width: 42.21%;
}

.percentageir-939:after {
  width: 42.255%;
}

.percentageir-940:after {
  width: 42.3%;
}

.percentageir-941:after {
  width: 42.345%;
}

.percentageir-942:after {
  width: 42.39%;
}

.percentageir-943:after {
  width: 42.435%;
}

.percentageir-944:after {
  width: 42.48%;
}

.percentageir-945:after {
  width: 42.525%;
}

.percentageir-946:after {
  width: 42.57%;
}

.percentageir-947:after {
  width: 42.615%;
}

.percentageir-948:after {
  width: 42.66%;
}

.percentageir-949:after {
  width: 42.705%;
}

.percentageir-950:after {
  width: 42.75%;
}

.percentageir-951:after {
  width: 42.795%;
}

.percentageir-952:after {
  width: 42.84%;
}

.percentageir-953:after {
  width: 42.885%;
}

.percentageir-954:after {
  width: 42.93%;
}

.percentageir-955:after {
  width: 42.975%;
}

.percentageir-956:after {
  width: 43.02%;
}

.percentageir-957:after {
  width: 43.065%;
}

.percentageir-958:after {
  width: 43.11%;
}

.percentageir-959:after {
  width: 43.155%;
}

.percentageir-960:after {
  width: 43.2%;
}

.percentageir-961:after {
  width: 43.245%;
}

.percentageir-962:after {
  width: 43.29%;
}

.percentageir-963:after {
  width: 43.335%;
}

.percentageir-964:after {
  width: 43.38%;
}

.percentageir-965:after {
  width: 43.425%;
}

.percentageir-966:after {
  width: 43.47%;
}

.percentageir-967:after {
  width: 43.515%;
}

.percentageir-968:after {
  width: 43.56%;
}

.percentageir-969:after {
  width: 43.605%;
}

.percentageir-970:after {
  width: 43.65%;
}

.percentageir-971:after {
  width: 43.695%;
}

.percentageir-972:after {
  width: 43.74%;
}

.percentageir-973:after {
  width: 43.785%;
}

.percentageir-974:after {
  width: 43.83%;
}

.percentageir-975:after {
  width: 43.875%;
}

.percentageir-976:after {
  width: 43.92%;
}

.percentageir-977:after {
  width: 43.965%;
}

.percentageir-978:after {
  width: 44.01%;
}

.percentageir-979:after {
  width: 44.055%;
}

.percentageir-980:after {
  width: 44.1%;
}

.percentageir-981:after {
  width: 44.145%;
}

.percentageir-982:after {
  width: 44.19%;
}

.percentageir-983:after {
  width: 44.235%;
}

.percentageir-984:after {
  width: 44.28%;
}

.percentageir-985:after {
  width: 44.325%;
}

.percentageir-986:after {
  width: 44.37%;
}

.percentageir-987:after {
  width: 44.415%;
}

.percentageir-988:after {
  width: 44.46%;
}

.percentageir-989:after {
  width: 44.505%;
}

.percentageir-990:after {
  width: 44.55%;
}

.percentageir-991:after {
  width: 44.595%;
}

.percentageir-992:after {
  width: 44.64%;
}

.percentageir-993:after {
  width: 44.685%;
}

.percentageir-994:after {
  width: 44.73%;
}

.percentageir-995:after {
  width: 44.775%;
}

.percentageir-996:after {
  width: 44.82%;
}

.percentageir-997:after {
  width: 44.865%;
}

.percentageir-998:after {
  width: 44.91%;
}

.percentageir-999:after {
  width: 44.955%;
}

.percentageir-1000:after {
  width: 45%;
}

.percentageir-1001:after {
  width: 45.045%;
}

.percentageir-1002:after {
  width: 45.09%;
}

.percentageir-1003:after {
  width: 45.135%;
}

.percentageir-1004:after {
  width: 45.18%;
}

.percentageir-1005:after {
  width: 45.225%;
}

.percentageir-1006:after {
  width: 45.27%;
}

.percentageir-1007:after {
  width: 45.315%;
}

.percentageir-1008:after {
  width: 45.36%;
}

.percentageir-1009:after {
  width: 45.405%;
}

.percentageir-1010:after {
  width: 45.45%;
}

.percentageir-1011:after {
  width: 45.495%;
}

.percentageir-1012:after {
  width: 45.54%;
}

.percentageir-1013:after {
  width: 45.585%;
}

.percentageir-1014:after {
  width: 45.63%;
}

.percentageir-1015:after {
  width: 45.675%;
}

.percentageir-1016:after {
  width: 45.72%;
}

.percentageir-1017:after {
  width: 45.765%;
}

.percentageir-1018:after {
  width: 45.81%;
}

.percentageir-1019:after {
  width: 45.855%;
}

.percentageir-1020:after {
  width: 45.9%;
}

.percentageir-1021:after {
  width: 45.945%;
}

.percentageir-1022:after {
  width: 45.99%;
}

.percentageir-1023:after {
  width: 46.035%;
}

.percentageir-1024:after {
  width: 46.08%;
}

.percentageir-1025:after {
  width: 46.125%;
}

.percentageir-1026:after {
  width: 46.17%;
}

.percentageir-1027:after {
  width: 46.215%;
}

.percentageir-1028:after {
  width: 46.26%;
}

.percentageir-1029:after {
  width: 46.305%;
}

.percentageir-1030:after {
  width: 46.35%;
}

.percentageir-1031:after {
  width: 46.395%;
}

.percentageir-1032:after {
  width: 46.44%;
}

.percentageir-1033:after {
  width: 46.485%;
}

.percentageir-1034:after {
  width: 46.53%;
}

.percentageir-1035:after {
  width: 46.575%;
}

.percentageir-1036:after {
  width: 46.62%;
}

.percentageir-1037:after {
  width: 46.665%;
}

.percentageir-1038:after {
  width: 46.71%;
}

.percentageir-1039:after {
  width: 46.755%;
}

.percentageir-1040:after {
  width: 46.8%;
}

.percentageir-1041:after {
  width: 46.845%;
}

.percentageir-1042:after {
  width: 46.89%;
}

.percentageir-1043:after {
  width: 46.935%;
}

.percentageir-1044:after {
  width: 46.98%;
}

.percentageir-1045:after {
  width: 47.025%;
}

.percentageir-1046:after {
  width: 47.07%;
}

.percentageir-1047:after {
  width: 47.115%;
}

.percentageir-1048:after {
  width: 47.16%;
}

.percentageir-1049:after {
  width: 47.205%;
}

.percentageir-1050:after {
  width: 47.25%;
}

.percentageir-1051:after {
  width: 47.295%;
}

.percentageir-1052:after {
  width: 47.34%;
}

.percentageir-1053:after {
  width: 47.385%;
}

.percentageir-1054:after {
  width: 47.43%;
}

.percentageir-1055:after {
  width: 47.475%;
}

.percentageir-1056:after {
  width: 47.52%;
}

.percentageir-1057:after {
  width: 47.565%;
}

.percentageir-1058:after {
  width: 47.61%;
}

.percentageir-1059:after {
  width: 47.655%;
}

.percentageir-1060:after {
  width: 47.7%;
}

.percentageir-1061:after {
  width: 47.745%;
}

.percentageir-1062:after {
  width: 47.79%;
}

.percentageir-1063:after {
  width: 47.835%;
}

.percentageir-1064:after {
  width: 47.88%;
}

.percentageir-1065:after {
  width: 47.925%;
}

.percentageir-1066:after {
  width: 47.97%;
}

.percentageir-1067:after {
  width: 48.015%;
}

.percentageir-1068:after {
  width: 48.06%;
}

.percentageir-1069:after {
  width: 48.105%;
}

.percentageir-1070:after {
  width: 48.15%;
}

.percentageir-1071:after {
  width: 48.195%;
}

.percentageir-1072:after {
  width: 48.24%;
}

.percentageir-1073:after {
  width: 48.285%;
}

.percentageir-1074:after {
  width: 48.33%;
}

.percentageir-1075:after {
  width: 48.375%;
}

.percentageir-1076:after {
  width: 48.42%;
}

.percentageir-1077:after {
  width: 48.465%;
}

.percentageir-1078:after {
  width: 48.51%;
}

.percentageir-1079:after {
  width: 48.555%;
}

.percentageir-1080:after {
  width: 48.6%;
}

.percentageir-1081:after {
  width: 48.645%;
}

.percentageir-1082:after {
  width: 48.69%;
}

.percentageir-1083:after {
  width: 48.735%;
}

.percentageir-1084:after {
  width: 48.78%;
}

.percentageir-1085:after {
  width: 48.825%;
}

.percentageir-1086:after {
  width: 48.87%;
}

.percentageir-1087:after {
  width: 48.915%;
}

.percentageir-1088:after {
  width: 48.96%;
}

.percentageir-1089:after {
  width: 49.005%;
}

.percentageir-1090:after {
  width: 49.05%;
}

.percentageir-1091:after {
  width: 49.095%;
}

.percentageir-1092:after {
  width: 49.14%;
}

.percentageir-1093:after {
  width: 49.185%;
}

.percentageir-1094:after {
  width: 49.23%;
}

.percentageir-1095:after {
  width: 49.275%;
}

.percentageir-1096:after {
  width: 49.32%;
}

.percentageir-1097:after {
  width: 49.365%;
}

.percentageir-1098:after {
  width: 49.41%;
}

.percentageir-1099:after {
  width: 49.455%;
}

.percentageir-1100:after {
  width: 49.5%;
}

.percentageir-1101:after {
  width: 49.545%;
}

.percentageir-1102:after {
  width: 49.59%;
}

.percentageir-1103:after {
  width: 49.635%;
}

.percentageir-1104:after {
  width: 49.68%;
}

.percentageir-1105:after {
  width: 49.725%;
}

.percentageir-1106:after {
  width: 49.77%;
}

.percentageir-1107:after {
  width: 49.815%;
}

.percentageir-1108:after {
  width: 49.86%;
}

.percentageir-1109:after {
  width: 49.905%;
}

.percentageir-1110:after {
  width: 49.95%;
}

.percentageir-1111:after {
  width: 49.995%;
}

.percentageir-1112:after {
  width: 50.04%;
}

.percentageir-1113:after {
  width: 50.085%;
}

.percentageir-1114:after {
  width: 50.13%;
}

.percentageir-1115:after {
  width: 50.175%;
}

.percentageir-1116:after {
  width: 50.22%;
}

.percentageir-1117:after {
  width: 50.265%;
}

.percentageir-1118:after {
  width: 50.31%;
}

.percentageir-1119:after {
  width: 50.355%;
}

.percentageir-1120:after {
  width: 50.4%;
}

.percentageir-1121:after {
  width: 50.445%;
}

.percentageir-1122:after {
  width: 50.49%;
}

.percentageir-1123:after {
  width: 50.535%;
}

.percentageir-1124:after {
  width: 50.58%;
}

.percentageir-1125:after {
  width: 50.625%;
}

.percentageir-1126:after {
  width: 50.67%;
}

.percentageir-1127:after {
  width: 50.715%;
}

.percentageir-1128:after {
  width: 50.76%;
}

.percentageir-1129:after {
  width: 50.805%;
}

.percentageir-1130:after {
  width: 50.85%;
}

.percentageir-1131:after {
  width: 50.895%;
}

.percentageir-1132:after {
  width: 50.94%;
}

.percentageir-1133:after {
  width: 50.985%;
}

.percentageir-1134:after {
  width: 51.03%;
}

.percentageir-1135:after {
  width: 51.075%;
}

.percentageir-1136:after {
  width: 51.12%;
}

.percentageir-1137:after {
  width: 51.165%;
}

.percentageir-1138:after {
  width: 51.21%;
}

.percentageir-1139:after {
  width: 51.255%;
}

.percentageir-1140:after {
  width: 51.3%;
}

.percentageir-1141:after {
  width: 51.345%;
}

.percentageir-1142:after {
  width: 51.39%;
}

.percentageir-1143:after {
  width: 51.435%;
}

.percentageir-1144:after {
  width: 51.48%;
}

.percentageir-1145:after {
  width: 51.525%;
}

.percentageir-1146:after {
  width: 51.57%;
}

.percentageir-1147:after {
  width: 51.615%;
}

.percentageir-1148:after {
  width: 51.66%;
}

.percentageir-1149:after {
  width: 51.705%;
}

.percentageir-1150:after {
  width: 51.75%;
}

.percentageir-1151:after {
  width: 51.795%;
}

.percentageir-1152:after {
  width: 51.84%;
}

.percentageir-1153:after {
  width: 51.885%;
}

.percentageir-1154:after {
  width: 51.93%;
}

.percentageir-1155:after {
  width: 51.975%;
}

.percentageir-1156:after {
  width: 52.02%;
}

.percentageir-1157:after {
  width: 52.065%;
}

.percentageir-1158:after {
  width: 52.11%;
}

.percentageir-1159:after {
  width: 52.155%;
}

.percentageir-1160:after {
  width: 52.2%;
}

.percentageir-1161:after {
  width: 52.245%;
}

.percentageir-1162:after {
  width: 52.29%;
}

.percentageir-1163:after {
  width: 52.335%;
}

.percentageir-1164:after {
  width: 52.38%;
}

.percentageir-1165:after {
  width: 52.425%;
}

.percentageir-1166:after {
  width: 52.47%;
}

.percentageir-1167:after {
  width: 52.515%;
}

.percentageir-1168:after {
  width: 52.56%;
}

.percentageir-1169:after {
  width: 52.605%;
}

.percentageir-1170:after {
  width: 52.65%;
}

.percentageir-1171:after {
  width: 52.695%;
}

.percentageir-1172:after {
  width: 52.74%;
}

.percentageir-1173:after {
  width: 52.785%;
}

.percentageir-1174:after {
  width: 52.83%;
}

.percentageir-1175:after {
  width: 52.875%;
}

.percentageir-1176:after {
  width: 52.92%;
}

.percentageir-1177:after {
  width: 52.965%;
}

.percentageir-1178:after {
  width: 53.01%;
}

.percentageir-1179:after {
  width: 53.055%;
}

.percentageir-1180:after {
  width: 53.1%;
}

.percentageir-1181:after {
  width: 53.145%;
}

.percentageir-1182:after {
  width: 53.19%;
}

.percentageir-1183:after {
  width: 53.235%;
}

.percentageir-1184:after {
  width: 53.28%;
}

.percentageir-1185:after {
  width: 53.325%;
}

.percentageir-1186:after {
  width: 53.37%;
}

.percentageir-1187:after {
  width: 53.415%;
}

.percentageir-1188:after {
  width: 53.46%;
}

.percentageir-1189:after {
  width: 53.505%;
}

.percentageir-1190:after {
  width: 53.55%;
}

.percentageir-1191:after {
  width: 53.595%;
}

.percentageir-1192:after {
  width: 53.64%;
}

.percentageir-1193:after {
  width: 53.685%;
}

.percentageir-1194:after {
  width: 53.73%;
}

.percentageir-1195:after {
  width: 53.775%;
}

.percentageir-1196:after {
  width: 53.82%;
}

.percentageir-1197:after {
  width: 53.865%;
}

.percentageir-1198:after {
  width: 53.91%;
}

.percentageir-1199:after {
  width: 53.955%;
}

.percentageir-1200:after {
  width: 54%;
}

.percentageir-1201:after {
  width: 54.045%;
}

.percentageir-1202:after {
  width: 54.09%;
}

.percentageir-1203:after {
  width: 54.135%;
}

.percentageir-1204:after {
  width: 54.18%;
}

.percentageir-1205:after {
  width: 54.225%;
}

.percentageir-1206:after {
  width: 54.27%;
}

.percentageir-1207:after {
  width: 54.315%;
}

.percentageir-1208:after {
  width: 54.36%;
}

.percentageir-1209:after {
  width: 54.405%;
}

.percentageir-1210:after {
  width: 54.45%;
}

.percentageir-1211:after {
  width: 54.495%;
}

.percentageir-1212:after {
  width: 54.54%;
}

.percentageir-1213:after {
  width: 54.585%;
}

.percentageir-1214:after {
  width: 54.63%;
}

.percentageir-1215:after {
  width: 54.675%;
}

.percentageir-1216:after {
  width: 54.72%;
}

.percentageir-1217:after {
  width: 54.765%;
}

.percentageir-1218:after {
  width: 54.81%;
}

.percentageir-1219:after {
  width: 54.855%;
}

.percentageir-1220:after {
  width: 54.9%;
}

.percentageir-1221:after {
  width: 54.945%;
}

.percentageir-1222:after {
  width: 54.99%;
}

.percentageir-1223:after {
  width: 55.035%;
}

.percentageir-1224:after {
  width: 55.08%;
}

.percentageir-1225:after {
  width: 55.125%;
}

.percentageir-1226:after {
  width: 55.17%;
}

.percentageir-1227:after {
  width: 55.215%;
}

.percentageir-1228:after {
  width: 55.26%;
}

.percentageir-1229:after {
  width: 55.305%;
}

.percentageir-1230:after {
  width: 55.35%;
}

.percentageir-1231:after {
  width: 55.395%;
}

.percentageir-1232:after {
  width: 55.44%;
}

.percentageir-1233:after {
  width: 55.485%;
}

.percentageir-1234:after {
  width: 55.53%;
}

.percentageir-1235:after {
  width: 55.575%;
}

.percentageir-1236:after {
  width: 55.62%;
}

.percentageir-1237:after {
  width: 55.665%;
}

.percentageir-1238:after {
  width: 55.71%;
}

.percentageir-1239:after {
  width: 55.755%;
}

.percentageir-1240:after {
  width: 55.8%;
}

.percentageir-1241:after {
  width: 55.845%;
}

.percentageir-1242:after {
  width: 55.89%;
}

.percentageir-1243:after {
  width: 55.935%;
}

.percentageir-1244:after {
  width: 55.98%;
}

.percentageir-1245:after {
  width: 56.025%;
}

.percentageir-1246:after {
  width: 56.07%;
}

.percentageir-1247:after {
  width: 56.115%;
}

.percentageir-1248:after {
  width: 56.16%;
}

.percentageir-1249:after {
  width: 56.205%;
}

.percentageir-1250:after {
  width: 56.25%;
}

.percentageir-1251:after {
  width: 56.295%;
}

.percentageir-1252:after {
  width: 56.34%;
}

.percentageir-1253:after {
  width: 56.385%;
}

.percentageir-1254:after {
  width: 56.43%;
}

.percentageir-1255:after {
  width: 56.475%;
}

.percentageir-1256:after {
  width: 56.52%;
}

.percentageir-1257:after {
  width: 56.565%;
}

.percentageir-1258:after {
  width: 56.61%;
}

.percentageir-1259:after {
  width: 56.655%;
}

.percentageir-1260:after {
  width: 56.7%;
}

.percentageir-1261:after {
  width: 56.745%;
}

.percentageir-1262:after {
  width: 56.79%;
}

.percentageir-1263:after {
  width: 56.835%;
}

.percentageir-1264:after {
  width: 56.88%;
}

.percentageir-1265:after {
  width: 56.925%;
}

.percentageir-1266:after {
  width: 56.97%;
}

.percentageir-1267:after {
  width: 57.015%;
}

.percentageir-1268:after {
  width: 57.06%;
}

.percentageir-1269:after {
  width: 57.105%;
}

.percentageir-1270:after {
  width: 57.15%;
}

.percentageir-1271:after {
  width: 57.195%;
}

.percentageir-1272:after {
  width: 57.24%;
}

.percentageir-1273:after {
  width: 57.285%;
}

.percentageir-1274:after {
  width: 57.33%;
}

.percentageir-1275:after {
  width: 57.375%;
}

.percentageir-1276:after {
  width: 57.42%;
}

.percentageir-1277:after {
  width: 57.465%;
}

.percentageir-1278:after {
  width: 57.51%;
}

.percentageir-1279:after {
  width: 57.555%;
}

.percentageir-1280:after {
  width: 57.6%;
}

.percentageir-1281:after {
  width: 57.645%;
}

.percentageir-1282:after {
  width: 57.69%;
}

.percentageir-1283:after {
  width: 57.735%;
}

.percentageir-1284:after {
  width: 57.78%;
}

.percentageir-1285:after {
  width: 57.825%;
}

.percentageir-1286:after {
  width: 57.87%;
}

.percentageir-1287:after {
  width: 57.915%;
}

.percentageir-1288:after {
  width: 57.96%;
}

.percentageir-1289:after {
  width: 58.005%;
}

.percentageir-1290:after {
  width: 58.05%;
}

.percentageir-1291:after {
  width: 58.095%;
}

.percentageir-1292:after {
  width: 58.14%;
}

.percentageir-1293:after {
  width: 58.185%;
}

.percentageir-1294:after {
  width: 58.23%;
}

.percentageir-1295:after {
  width: 58.275%;
}

.percentageir-1296:after {
  width: 58.32%;
}

.percentageir-1297:after {
  width: 58.365%;
}

.percentageir-1298:after {
  width: 58.41%;
}

.percentageir-1299:after {
  width: 58.455%;
}

.percentageir-1300:after {
  width: 58.5%;
}

.percentageir-1301:after {
  width: 58.545%;
}

.percentageir-1302:after {
  width: 58.59%;
}

.percentageir-1303:after {
  width: 58.635%;
}

.percentageir-1304:after {
  width: 58.68%;
}

.percentageir-1305:after {
  width: 58.725%;
}

.percentageir-1306:after {
  width: 58.77%;
}

.percentageir-1307:after {
  width: 58.815%;
}

.percentageir-1308:after {
  width: 58.86%;
}

.percentageir-1309:after {
  width: 58.905%;
}

.percentageir-1310:after {
  width: 58.95%;
}

.percentageir-1311:after {
  width: 58.995%;
}

.percentageir-1312:after {
  width: 59.04%;
}

.percentageir-1313:after {
  width: 59.085%;
}

.percentageir-1314:after {
  width: 59.13%;
}

.percentageir-1315:after {
  width: 59.175%;
}

.percentageir-1316:after {
  width: 59.22%;
}

.percentageir-1317:after {
  width: 59.265%;
}

.percentageir-1318:after {
  width: 59.31%;
}

.percentageir-1319:after {
  width: 59.355%;
}

.percentageir-1320:after {
  width: 59.4%;
}

.percentageir-1321:after {
  width: 59.445%;
}

.percentageir-1322:after {
  width: 59.49%;
}

.percentageir-1323:after {
  width: 59.535%;
}

.percentageir-1324:after {
  width: 59.58%;
}

.percentageir-1325:after {
  width: 59.625%;
}

.percentageir-1326:after {
  width: 59.67%;
}

.percentageir-1327:after {
  width: 59.715%;
}

.percentageir-1328:after {
  width: 59.76%;
}

.percentageir-1329:after {
  width: 59.805%;
}

.percentageir-1330:after {
  width: 59.85%;
}

.percentageir-1331:after {
  width: 59.895%;
}

.percentageir-1332:after {
  width: 59.94%;
}

.percentageir-1333:after {
  width: 59.985%;
}

.percentageir-1334:after {
  width: 60.03%;
}

.percentageir-1335:after {
  width: 60.075%;
}

.percentageir-1336:after {
  width: 60.12%;
}

.percentageir-1337:after {
  width: 60.165%;
}

.percentageir-1338:after {
  width: 60.21%;
}

.percentageir-1339:after {
  width: 60.255%;
}

.percentageir-1340:after {
  width: 60.3%;
}

.percentageir-1341:after {
  width: 60.345%;
}

.percentageir-1342:after {
  width: 60.39%;
}

.percentageir-1343:after {
  width: 60.435%;
}

.percentageir-1344:after {
  width: 60.48%;
}

.percentageir-1345:after {
  width: 60.525%;
}

.percentageir-1346:after {
  width: 60.57%;
}

.percentageir-1347:after {
  width: 60.615%;
}

.percentageir-1348:after {
  width: 60.66%;
}

.percentageir-1349:after {
  width: 60.705%;
}

.percentageir-1350:after {
  width: 60.75%;
}

.percentageir-1351:after {
  width: 60.795%;
}

.percentageir-1352:after {
  width: 60.84%;
}

.percentageir-1353:after {
  width: 60.885%;
}

.percentageir-1354:after {
  width: 60.93%;
}

.percentageir-1355:after {
  width: 60.975%;
}

.percentageir-1356:after {
  width: 61.02%;
}

.percentageir-1357:after {
  width: 61.065%;
}

.percentageir-1358:after {
  width: 61.11%;
}

.percentageir-1359:after {
  width: 61.155%;
}

.percentageir-1360:after {
  width: 61.2%;
}

.percentageir-1361:after {
  width: 61.245%;
}

.percentageir-1362:after {
  width: 61.29%;
}

.percentageir-1363:after {
  width: 61.335%;
}

.percentageir-1364:after {
  width: 61.38%;
}

.percentageir-1365:after {
  width: 61.425%;
}

.percentageir-1366:after {
  width: 61.47%;
}

.percentageir-1367:after {
  width: 61.515%;
}

.percentageir-1368:after {
  width: 61.56%;
}

.percentageir-1369:after {
  width: 61.605%;
}

.percentageir-1370:after {
  width: 61.65%;
}

.percentageir-1371:after {
  width: 61.695%;
}

.percentageir-1372:after {
  width: 61.74%;
}

.percentageir-1373:after {
  width: 61.785%;
}

.percentageir-1374:after {
  width: 61.83%;
}

.percentageir-1375:after {
  width: 61.875%;
}

.percentageir-1376:after {
  width: 61.92%;
}

.percentageir-1377:after {
  width: 61.965%;
}

.percentageir-1378:after {
  width: 62.01%;
}

.percentageir-1379:after {
  width: 62.055%;
}

.percentageir-1380:after {
  width: 62.1%;
}

.percentageir-1381:after {
  width: 62.145%;
}

.percentageir-1382:after {
  width: 62.19%;
}

.percentageir-1383:after {
  width: 62.235%;
}

.percentageir-1384:after {
  width: 62.28%;
}

.percentageir-1385:after {
  width: 62.325%;
}

.percentageir-1386:after {
  width: 62.37%;
}

.percentageir-1387:after {
  width: 62.415%;
}

.percentageir-1388:after {
  width: 62.46%;
}

.percentageir-1389:after {
  width: 62.505%;
}

.percentageir-1390:after {
  width: 62.55%;
}

.percentageir-1391:after {
  width: 62.595%;
}

.percentageir-1392:after {
  width: 62.64%;
}

.percentageir-1393:after {
  width: 62.685%;
}

.percentageir-1394:after {
  width: 62.73%;
}

.percentageir-1395:after {
  width: 62.775%;
}

.percentageir-1396:after {
  width: 62.82%;
}

.percentageir-1397:after {
  width: 62.865%;
}

.percentageir-1398:after {
  width: 62.91%;
}

.percentageir-1399:after {
  width: 62.955%;
}

.percentageir-1400:after {
  width: 63%;
}

.percentageir-1401:after {
  width: 63.045%;
}

.percentageir-1402:after {
  width: 63.09%;
}

.percentageir-1403:after {
  width: 63.135%;
}

.percentageir-1404:after {
  width: 63.18%;
}

.percentageir-1405:after {
  width: 63.225%;
}

.percentageir-1406:after {
  width: 63.27%;
}

.percentageir-1407:after {
  width: 63.315%;
}

.percentageir-1408:after {
  width: 63.36%;
}

.percentageir-1409:after {
  width: 63.405%;
}

.percentageir-1410:after {
  width: 63.45%;
}

.percentageir-1411:after {
  width: 63.495%;
}

.percentageir-1412:after {
  width: 63.54%;
}

.percentageir-1413:after {
  width: 63.585%;
}

.percentageir-1414:after {
  width: 63.63%;
}

.percentageir-1415:after {
  width: 63.675%;
}

.percentageir-1416:after {
  width: 63.72%;
}

.percentageir-1417:after {
  width: 63.765%;
}

.percentageir-1418:after {
  width: 63.81%;
}

.percentageir-1419:after {
  width: 63.855%;
}

.percentageir-1420:after {
  width: 63.9%;
}

.percentageir-1421:after {
  width: 63.945%;
}

.percentageir-1422:after {
  width: 63.99%;
}

.percentageir-1423:after {
  width: 64.035%;
}

.percentageir-1424:after {
  width: 64.08%;
}

.percentageir-1425:after {
  width: 64.125%;
}

.percentageir-1426:after {
  width: 64.17%;
}

.percentageir-1427:after {
  width: 64.215%;
}

.percentageir-1428:after {
  width: 64.26%;
}

.percentageir-1429:after {
  width: 64.305%;
}

.percentageir-1430:after {
  width: 64.35%;
}

.percentageir-1431:after {
  width: 64.395%;
}

.percentageir-1432:after {
  width: 64.44%;
}

.percentageir-1433:after {
  width: 64.485%;
}

.percentageir-1434:after {
  width: 64.53%;
}

.percentageir-1435:after {
  width: 64.575%;
}

.percentageir-1436:after {
  width: 64.62%;
}

.percentageir-1437:after {
  width: 64.665%;
}

.percentageir-1438:after {
  width: 64.71%;
}

.percentageir-1439:after {
  width: 64.755%;
}

.percentageir-1440:after {
  width: 64.8%;
}

.percentageir-1441:after {
  width: 64.845%;
}

.percentageir-1442:after {
  width: 64.89%;
}

.percentageir-1443:after {
  width: 64.935%;
}

.percentageir-1444:after {
  width: 64.98%;
}

.percentageir-1445:after {
  width: 65.025%;
}

.percentageir-1446:after {
  width: 65.07%;
}

.percentageir-1447:after {
  width: 65.115%;
}

.percentageir-1448:after {
  width: 65.16%;
}

.percentageir-1449:after {
  width: 65.205%;
}

.percentageir-1450:after {
  width: 65.25%;
}

.percentageir-1451:after {
  width: 65.295%;
}

.percentageir-1452:after {
  width: 65.34%;
}

.percentageir-1453:after {
  width: 65.385%;
}

.percentageir-1454:after {
  width: 65.43%;
}

.percentageir-1455:after {
  width: 65.475%;
}

.percentageir-1456:after {
  width: 65.52%;
}

.percentageir-1457:after {
  width: 65.565%;
}

.percentageir-1458:after {
  width: 65.61%;
}

.percentageir-1459:after {
  width: 65.655%;
}

.percentageir-1460:after {
  width: 65.7%;
}

.percentageir-1461:after {
  width: 65.745%;
}

.percentageir-1462:after {
  width: 65.79%;
}

.percentageir-1463:after {
  width: 65.835%;
}

.percentageir-1464:after {
  width: 65.88%;
}

.percentageir-1465:after {
  width: 65.925%;
}

.percentageir-1466:after {
  width: 65.97%;
}

.percentageir-1467:after {
  width: 66.015%;
}

.percentageir-1468:after {
  width: 66.06%;
}

.percentageir-1469:after {
  width: 66.105%;
}

.percentageir-1470:after {
  width: 66.15%;
}

.percentageir-1471:after {
  width: 66.195%;
}

.percentageir-1472:after {
  width: 66.24%;
}

.percentageir-1473:after {
  width: 66.285%;
}

.percentageir-1474:after {
  width: 66.33%;
}

.percentageir-1475:after {
  width: 66.375%;
}

.percentageir-1476:after {
  width: 66.42%;
}

.percentageir-1477:after {
  width: 66.465%;
}

.percentageir-1478:after {
  width: 66.51%;
}

.percentageir-1479:after {
  width: 66.555%;
}

.percentageir-1480:after {
  width: 66.6%;
}

.percentageir-1481:after {
  width: 66.645%;
}

.percentageir-1482:after {
  width: 66.69%;
}

.percentageir-1483:after {
  width: 66.735%;
}

.percentageir-1484:after {
  width: 66.78%;
}

.percentageir-1485:after {
  width: 66.825%;
}

.percentageir-1486:after {
  width: 66.87%;
}

.percentageir-1487:after {
  width: 66.915%;
}

.percentageir-1488:after {
  width: 66.96%;
}

.percentageir-1489:after {
  width: 67.005%;
}

.percentageir-1490:after {
  width: 67.05%;
}

.percentageir-1491:after {
  width: 67.095%;
}

.percentageir-1492:after {
  width: 67.14%;
}

.percentageir-1493:after {
  width: 67.185%;
}

.percentageir-1494:after {
  width: 67.23%;
}

.percentageir-1495:after {
  width: 67.275%;
}

.percentageir-1496:after {
  width: 67.32%;
}

.percentageir-1497:after {
  width: 67.365%;
}

.percentageir-1498:after {
  width: 67.41%;
}

.percentageir-1499:after {
  width: 67.455%;
}

.percentageir-1500:after {
  width: 67.5%;
}

.percentageir-1501:after {
  width: 67.545%;
}

.percentageir-1502:after {
  width: 67.59%;
}

.percentageir-1503:after {
  width: 67.635%;
}

.percentageir-1504:after {
  width: 67.68%;
}

.percentageir-1505:after {
  width: 67.725%;
}

.percentageir-1506:after {
  width: 67.77%;
}

.percentageir-1507:after {
  width: 67.815%;
}

.percentageir-1508:after {
  width: 67.86%;
}

.percentageir-1509:after {
  width: 67.905%;
}

.percentageir-1510:after {
  width: 67.95%;
}

.percentageir-1511:after {
  width: 67.995%;
}

.percentageir-1512:after {
  width: 68.04%;
}

.percentageir-1513:after {
  width: 68.085%;
}

.percentageir-1514:after {
  width: 68.13%;
}

.percentageir-1515:after {
  width: 68.175%;
}

.percentageir-1516:after {
  width: 68.22%;
}

.percentageir-1517:after {
  width: 68.265%;
}

.percentageir-1518:after {
  width: 68.31%;
}

.percentageir-1519:after {
  width: 68.355%;
}

.percentageir-1520:after {
  width: 68.4%;
}

.percentageir-1521:after {
  width: 68.445%;
}

.percentageir-1522:after {
  width: 68.49%;
}

.percentageir-1523:after {
  width: 68.535%;
}

.percentageir-1524:after {
  width: 68.58%;
}

.percentageir-1525:after {
  width: 68.625%;
}

.percentageir-1526:after {
  width: 68.67%;
}

.percentageir-1527:after {
  width: 68.715%;
}

.percentageir-1528:after {
  width: 68.76%;
}

.percentageir-1529:after {
  width: 68.805%;
}

.percentageir-1530:after {
  width: 68.85%;
}

.percentageir-1531:after {
  width: 68.895%;
}

.percentageir-1532:after {
  width: 68.94%;
}

.percentageir-1533:after {
  width: 68.985%;
}

.percentageir-1534:after {
  width: 69.03%;
}

.percentageir-1535:after {
  width: 69.075%;
}

.percentageir-1536:after {
  width: 69.12%;
}

.percentageir-1537:after {
  width: 69.165%;
}

.percentageir-1538:after {
  width: 69.21%;
}

.percentageir-1539:after {
  width: 69.255%;
}

.percentageir-1540:after {
  width: 69.3%;
}

.percentageir-1541:after {
  width: 69.345%;
}

.percentageir-1542:after {
  width: 69.39%;
}

.percentageir-1543:after {
  width: 69.435%;
}

.percentageir-1544:after {
  width: 69.48%;
}

.percentageir-1545:after {
  width: 69.525%;
}

.percentageir-1546:after {
  width: 69.57%;
}

.percentageir-1547:after {
  width: 69.615%;
}

.percentageir-1548:after {
  width: 69.66%;
}

.percentageir-1549:after {
  width: 69.705%;
}

.percentageir-1550:after {
  width: 69.75%;
}

.percentageir-1551:after {
  width: 69.795%;
}

.percentageir-1552:after {
  width: 69.84%;
}

.percentageir-1553:after {
  width: 69.885%;
}

.percentageir-1554:after {
  width: 69.93%;
}

.percentageir-1555:after {
  width: 69.975%;
}

.percentageir-1556:after {
  width: 70.02%;
}

.percentageir-1557:after {
  width: 70.065%;
}

.percentageir-1558:after {
  width: 70.11%;
}

.percentageir-1559:after {
  width: 70.155%;
}

.percentageir-1560:after {
  width: 70.2%;
}

.percentageir-1561:after {
  width: 70.245%;
}

.percentageir-1562:after {
  width: 70.29%;
}

.percentageir-1563:after {
  width: 70.335%;
}

.percentageir-1564:after {
  width: 70.38%;
}

.percentageir-1565:after {
  width: 70.425%;
}

.percentageir-1566:after {
  width: 70.47%;
}

.percentageir-1567:after {
  width: 70.515%;
}

.percentageir-1568:after {
  width: 70.56%;
}

.percentageir-1569:after {
  width: 70.605%;
}

.percentageir-1570:after {
  width: 70.65%;
}

.percentageir-1571:after {
  width: 70.695%;
}

.percentageir-1572:after {
  width: 70.74%;
}

.percentageir-1573:after {
  width: 70.785%;
}

.percentageir-1574:after {
  width: 70.83%;
}

.percentageir-1575:after {
  width: 70.875%;
}

.percentageir-1576:after {
  width: 70.92%;
}

.percentageir-1577:after {
  width: 70.965%;
}

.percentageir-1578:after {
  width: 71.01%;
}

.percentageir-1579:after {
  width: 71.055%;
}

.percentageir-1580:after {
  width: 71.1%;
}

.percentageir-1581:after {
  width: 71.145%;
}

.percentageir-1582:after {
  width: 71.19%;
}

.percentageir-1583:after {
  width: 71.235%;
}

.percentageir-1584:after {
  width: 71.28%;
}

.percentageir-1585:after {
  width: 71.325%;
}

.percentageir-1586:after {
  width: 71.37%;
}

.percentageir-1587:after {
  width: 71.415%;
}

.percentageir-1588:after {
  width: 71.46%;
}

.percentageir-1589:after {
  width: 71.505%;
}

.percentageir-1590:after {
  width: 71.55%;
}

.percentageir-1591:after {
  width: 71.595%;
}

.percentageir-1592:after {
  width: 71.64%;
}

.percentageir-1593:after {
  width: 71.685%;
}

.percentageir-1594:after {
  width: 71.73%;
}

.percentageir-1595:after {
  width: 71.775%;
}

.percentageir-1596:after {
  width: 71.82%;
}

.percentageir-1597:after {
  width: 71.865%;
}

.percentageir-1598:after {
  width: 71.91%;
}

.percentageir-1599:after {
  width: 71.955%;
}

.percentageir-1600:after {
  width: 72%;
}

.percentageir-1601:after {
  width: 72.045%;
}

.percentageir-1602:after {
  width: 72.09%;
}

.percentageir-1603:after {
  width: 72.135%;
}

.percentageir-1604:after {
  width: 72.18%;
}

.percentageir-1605:after {
  width: 72.225%;
}

.percentageir-1606:after {
  width: 72.27%;
}

.percentageir-1607:after {
  width: 72.315%;
}

.percentageir-1608:after {
  width: 72.36%;
}

.percentageir-1609:after {
  width: 72.405%;
}

.percentageir-1610:after {
  width: 72.45%;
}

.percentageir-1611:after {
  width: 72.495%;
}

.percentageir-1612:after {
  width: 72.54%;
}

.percentageir-1613:after {
  width: 72.585%;
}

.percentageir-1614:after {
  width: 72.63%;
}

.percentageir-1615:after {
  width: 72.675%;
}

.percentageir-1616:after {
  width: 72.72%;
}

.percentageir-1617:after {
  width: 72.765%;
}

.percentageir-1618:after {
  width: 72.81%;
}

.percentageir-1619:after {
  width: 72.855%;
}

.percentageir-1620:after {
  width: 72.9%;
}

.percentageir-1621:after {
  width: 72.945%;
}

.percentageir-1622:after {
  width: 72.99%;
}

.percentageir-1623:after {
  width: 73.035%;
}

.percentageir-1624:after {
  width: 73.08%;
}

.percentageir-1625:after {
  width: 73.125%;
}

.percentageir-1626:after {
  width: 73.17%;
}

.percentageir-1627:after {
  width: 73.215%;
}

.percentageir-1628:after {
  width: 73.26%;
}

.percentageir-1629:after {
  width: 73.305%;
}

.percentageir-1630:after {
  width: 73.35%;
}

.percentageir-1631:after {
  width: 73.395%;
}

.percentageir-1632:after {
  width: 73.44%;
}

.percentageir-1633:after {
  width: 73.485%;
}

.percentageir-1634:after {
  width: 73.53%;
}

.percentageir-1635:after {
  width: 73.575%;
}

.percentageir-1636:after {
  width: 73.62%;
}

.percentageir-1637:after {
  width: 73.665%;
}

.percentageir-1638:after {
  width: 73.71%;
}

.percentageir-1639:after {
  width: 73.755%;
}

.percentageir-1640:after {
  width: 73.8%;
}

.percentageir-1641:after {
  width: 73.845%;
}

.percentageir-1642:after {
  width: 73.89%;
}

.percentageir-1643:after {
  width: 73.935%;
}

.percentageir-1644:after {
  width: 73.98%;
}

.percentageir-1645:after {
  width: 74.025%;
}

.percentageir-1646:after {
  width: 74.07%;
}

.percentageir-1647:after {
  width: 74.115%;
}

.percentageir-1648:after {
  width: 74.16%;
}

.percentageir-1649:after {
  width: 74.205%;
}

.percentageir-1650:after {
  width: 74.25%;
}

.percentageir-1651:after {
  width: 74.295%;
}

.percentageir-1652:after {
  width: 74.34%;
}

.percentageir-1653:after {
  width: 74.385%;
}

.percentageir-1654:after {
  width: 74.43%;
}

.percentageir-1655:after {
  width: 74.475%;
}

.percentageir-1656:after {
  width: 74.52%;
}

.percentageir-1657:after {
  width: 74.565%;
}

.percentageir-1658:after {
  width: 74.61%;
}

.percentageir-1659:after {
  width: 74.655%;
}

.percentageir-1660:after {
  width: 74.7%;
}

.percentageir-1661:after {
  width: 74.745%;
}

.percentageir-1662:after {
  width: 74.79%;
}

.percentageir-1663:after {
  width: 74.835%;
}

.percentageir-1664:after {
  width: 74.88%;
}

.percentageir-1665:after {
  width: 74.925%;
}

.percentageir-1666:after {
  width: 74.97%;
}

.percentageir-1667:after {
  width: 75.015%;
}

.percentageir-1668:after {
  width: 75.06%;
}

.percentageir-1669:after {
  width: 75.105%;
}

.percentageir-1670:after {
  width: 75.15%;
}

.percentageir-1671:after {
  width: 75.195%;
}

.percentageir-1672:after {
  width: 75.24%;
}

.percentageir-1673:after {
  width: 75.285%;
}

.percentageir-1674:after {
  width: 75.33%;
}

.percentageir-1675:after {
  width: 75.375%;
}

.percentageir-1676:after {
  width: 75.42%;
}

.percentageir-1677:after {
  width: 75.465%;
}

.percentageir-1678:after {
  width: 75.51%;
}

.percentageir-1679:after {
  width: 75.555%;
}

.percentageir-1680:after {
  width: 75.6%;
}

.percentageir-1681:after {
  width: 75.645%;
}

.percentageir-1682:after {
  width: 75.69%;
}

.percentageir-1683:after {
  width: 75.735%;
}

.percentageir-1684:after {
  width: 75.78%;
}

.percentageir-1685:after {
  width: 75.825%;
}

.percentageir-1686:after {
  width: 75.87%;
}

.percentageir-1687:after {
  width: 75.915%;
}

.percentageir-1688:after {
  width: 75.96%;
}

.percentageir-1689:after {
  width: 76.005%;
}

.percentageir-1690:after {
  width: 76.05%;
}

.percentageir-1691:after {
  width: 76.095%;
}

.percentageir-1692:after {
  width: 76.14%;
}

.percentageir-1693:after {
  width: 76.185%;
}

.percentageir-1694:after {
  width: 76.23%;
}

.percentageir-1695:after {
  width: 76.275%;
}

.percentageir-1696:after {
  width: 76.32%;
}

.percentageir-1697:after {
  width: 76.365%;
}

.percentageir-1698:after {
  width: 76.41%;
}

.percentageir-1699:after {
  width: 76.455%;
}

.percentageir-1700:after {
  width: 76.5%;
}

.percentageir-1701:after {
  width: 76.545%;
}

.percentageir-1702:after {
  width: 76.59%;
}

.percentageir-1703:after {
  width: 76.635%;
}

.percentageir-1704:after {
  width: 76.68%;
}

.percentageir-1705:after {
  width: 76.725%;
}

.percentageir-1706:after {
  width: 76.77%;
}

.percentageir-1707:after {
  width: 76.815%;
}

.percentageir-1708:after {
  width: 76.86%;
}

.percentageir-1709:after {
  width: 76.905%;
}

.percentageir-1710:after {
  width: 76.95%;
}

.percentageir-1711:after {
  width: 76.995%;
}

.percentageir-1712:after {
  width: 77.04%;
}

.percentageir-1713:after {
  width: 77.085%;
}

.percentageir-1714:after {
  width: 77.13%;
}

.percentageir-1715:after {
  width: 77.175%;
}

.percentageir-1716:after {
  width: 77.22%;
}

.percentageir-1717:after {
  width: 77.265%;
}

.percentageir-1718:after {
  width: 77.31%;
}

.percentageir-1719:after {
  width: 77.355%;
}

.percentageir-1720:after {
  width: 77.4%;
}

.percentageir-1721:after {
  width: 77.445%;
}

.percentageir-1722:after {
  width: 77.49%;
}

.percentageir-1723:after {
  width: 77.535%;
}

.percentageir-1724:after {
  width: 77.58%;
}

.percentageir-1725:after {
  width: 77.625%;
}

.percentageir-1726:after {
  width: 77.67%;
}

.percentageir-1727:after {
  width: 77.715%;
}

.percentageir-1728:after {
  width: 77.76%;
}

.percentageir-1729:after {
  width: 77.805%;
}

.percentageir-1730:after {
  width: 77.85%;
}

.percentageir-1731:after {
  width: 77.895%;
}

.percentageir-1732:after {
  width: 77.94%;
}

.percentageir-1733:after {
  width: 77.985%;
}

.percentageir-1734:after {
  width: 78.03%;
}

.percentageir-1735:after {
  width: 78.075%;
}

.percentageir-1736:after {
  width: 78.12%;
}

.percentageir-1737:after {
  width: 78.165%;
}

.percentageir-1738:after {
  width: 78.21%;
}

.percentageir-1739:after {
  width: 78.255%;
}

.percentageir-1740:after {
  width: 78.3%;
}

.percentageir-1741:after {
  width: 78.345%;
}

.percentageir-1742:after {
  width: 78.39%;
}

.percentageir-1743:after {
  width: 78.435%;
}

.percentageir-1744:after {
  width: 78.48%;
}

.percentageir-1745:after {
  width: 78.525%;
}

.percentageir-1746:after {
  width: 78.57%;
}

.percentageir-1747:after {
  width: 78.615%;
}

.percentageir-1748:after {
  width: 78.66%;
}

.percentageir-1749:after {
  width: 78.705%;
}

.percentageir-1750:after {
  width: 78.75%;
}

.percentageir-1751:after {
  width: 78.795%;
}

.percentageir-1752:after {
  width: 78.84%;
}

.percentageir-1753:after {
  width: 78.885%;
}

.percentageir-1754:after {
  width: 78.93%;
}

.percentageir-1755:after {
  width: 78.975%;
}

.percentageir-1756:after {
  width: 79.02%;
}

.percentageir-1757:after {
  width: 79.065%;
}

.percentageir-1758:after {
  width: 79.11%;
}

.percentageir-1759:after {
  width: 79.155%;
}

.percentageir-1760:after {
  width: 79.2%;
}

.percentageir-1761:after {
  width: 79.245%;
}

.percentageir-1762:after {
  width: 79.29%;
}

.percentageir-1763:after {
  width: 79.335%;
}

.percentageir-1764:after {
  width: 79.38%;
}

.percentageir-1765:after {
  width: 79.425%;
}

.percentageir-1766:after {
  width: 79.47%;
}

.percentageir-1767:after {
  width: 79.515%;
}

.percentageir-1768:after {
  width: 79.56%;
}

.percentageir-1769:after {
  width: 79.605%;
}

.percentageir-1770:after {
  width: 79.65%;
}

.percentageir-1771:after {
  width: 79.695%;
}

.percentageir-1772:after {
  width: 79.74%;
}

.percentageir-1773:after {
  width: 79.785%;
}

.percentageir-1774:after {
  width: 79.83%;
}

.percentageir-1775:after {
  width: 79.875%;
}

.percentageir-1776:after {
  width: 79.92%;
}

.percentageir-1777:after {
  width: 79.965%;
}

.percentageir-1778:after {
  width: 80.01%;
}

.percentageir-1779:after {
  width: 80.055%;
}

.percentageir-1780:after {
  width: 80.1%;
}

.percentageir-1781:after {
  width: 80.145%;
}

.percentageir-1782:after {
  width: 80.19%;
}

.percentageir-1783:after {
  width: 80.235%;
}

.percentageir-1784:after {
  width: 80.28%;
}

.percentageir-1785:after {
  width: 80.325%;
}

.percentageir-1786:after {
  width: 80.37%;
}

.percentageir-1787:after {
  width: 80.415%;
}

.percentageir-1788:after {
  width: 80.46%;
}

.percentageir-1789:after {
  width: 80.505%;
}

.percentageir-1790:after {
  width: 80.55%;
}

.percentageir-1791:after {
  width: 80.595%;
}

.percentageir-1792:after {
  width: 80.64%;
}

.percentageir-1793:after {
  width: 80.685%;
}

.percentageir-1794:after {
  width: 80.73%;
}

.percentageir-1795:after {
  width: 80.775%;
}

.percentageir-1796:after {
  width: 80.82%;
}

.percentageir-1797:after {
  width: 80.865%;
}

.percentageir-1798:after {
  width: 80.91%;
}

.percentageir-1799:after {
  width: 80.955%;
}

.percentageir-1800:after {
  width: 81%;
}

.percentageir-1801:after {
  width: 81.045%;
}

.percentageir-1802:after {
  width: 81.09%;
}

.percentageir-1803:after {
  width: 81.135%;
}

.percentageir-1804:after {
  width: 81.18%;
}

.percentageir-1805:after {
  width: 81.225%;
}

.percentageir-1806:after {
  width: 81.27%;
}

.percentageir-1807:after {
  width: 81.315%;
}

.percentageir-1808:after {
  width: 81.36%;
}

.percentageir-1809:after {
  width: 81.405%;
}

.percentageir-1810:after {
  width: 81.45%;
}

.percentageir-1811:after {
  width: 81.495%;
}

.percentageir-1812:after {
  width: 81.54%;
}

.percentageir-1813:after {
  width: 81.585%;
}

.percentageir-1814:after {
  width: 81.63%;
}

.percentageir-1815:after {
  width: 81.675%;
}

.percentageir-1816:after {
  width: 81.72%;
}

.percentageir-1817:after {
  width: 81.765%;
}

.percentageir-1818:after {
  width: 81.81%;
}

.percentageir-1819:after {
  width: 81.855%;
}

.percentageir-1820:after {
  width: 81.9%;
}

.percentageir-1821:after {
  width: 81.945%;
}

.percentageir-1822:after {
  width: 81.99%;
}

.percentageir-1823:after {
  width: 82.035%;
}

.percentageir-1824:after {
  width: 82.08%;
}

.percentageir-1825:after {
  width: 82.125%;
}

.percentageir-1826:after {
  width: 82.17%;
}

.percentageir-1827:after {
  width: 82.215%;
}

.percentageir-1828:after {
  width: 82.26%;
}

.percentageir-1829:after {
  width: 82.305%;
}

.percentageir-1830:after {
  width: 82.35%;
}

.percentageir-1831:after {
  width: 82.395%;
}

.percentageir-1832:after {
  width: 82.44%;
}

.percentageir-1833:after {
  width: 82.485%;
}

.percentageir-1834:after {
  width: 82.53%;
}

.percentageir-1835:after {
  width: 82.575%;
}

.percentageir-1836:after {
  width: 82.62%;
}

.percentageir-1837:after {
  width: 82.665%;
}

.percentageir-1838:after {
  width: 82.71%;
}

.percentageir-1839:after {
  width: 82.755%;
}

.percentageir-1840:after {
  width: 82.8%;
}

.percentageir-1841:after {
  width: 82.845%;
}

.percentageir-1842:after {
  width: 82.89%;
}

.percentageir-1843:after {
  width: 82.935%;
}

.percentageir-1844:after {
  width: 82.98%;
}

.percentageir-1845:after {
  width: 83.025%;
}

.percentageir-1846:after {
  width: 83.07%;
}

.percentageir-1847:after {
  width: 83.115%;
}

.percentageir-1848:after {
  width: 83.16%;
}

.percentageir-1849:after {
  width: 83.205%;
}

.percentageir-1850:after {
  width: 83.25%;
}

.percentageir-1851:after {
  width: 83.295%;
}

.percentageir-1852:after {
  width: 83.34%;
}

.percentageir-1853:after {
  width: 83.385%;
}

.percentageir-1854:after {
  width: 83.43%;
}

.percentageir-1855:after {
  width: 83.475%;
}

.percentageir-1856:after {
  width: 83.52%;
}

.percentageir-1857:after {
  width: 83.565%;
}

.percentageir-1858:after {
  width: 83.61%;
}

.percentageir-1859:after {
  width: 83.655%;
}

.percentageir-1860:after {
  width: 83.7%;
}

.percentageir-1861:after {
  width: 83.745%;
}

.percentageir-1862:after {
  width: 83.79%;
}

.percentageir-1863:after {
  width: 83.835%;
}

.percentageir-1864:after {
  width: 83.88%;
}

.percentageir-1865:after {
  width: 83.925%;
}

.percentageir-1866:after {
  width: 83.97%;
}

.percentageir-1867:after {
  width: 84.015%;
}

.percentageir-1868:after {
  width: 84.06%;
}

.percentageir-1869:after {
  width: 84.105%;
}

.percentageir-1870:after {
  width: 84.15%;
}

.percentageir-1871:after {
  width: 84.195%;
}

.percentageir-1872:after {
  width: 84.24%;
}

.percentageir-1873:after {
  width: 84.285%;
}

.percentageir-1874:after {
  width: 84.33%;
}

.percentageir-1875:after {
  width: 84.375%;
}

.percentageir-1876:after {
  width: 84.42%;
}

.percentageir-1877:after {
  width: 84.465%;
}

.percentageir-1878:after {
  width: 84.51%;
}

.percentageir-1879:after {
  width: 84.555%;
}

.percentageir-1880:after {
  width: 84.6%;
}

.percentageir-1881:after {
  width: 84.645%;
}

.percentageir-1882:after {
  width: 84.69%;
}

.percentageir-1883:after {
  width: 84.735%;
}

.percentageir-1884:after {
  width: 84.78%;
}

.percentageir-1885:after {
  width: 84.825%;
}

.percentageir-1886:after {
  width: 84.87%;
}

.percentageir-1887:after {
  width: 84.915%;
}

.percentageir-1888:after {
  width: 84.96%;
}

.percentageir-1889:after {
  width: 85.005%;
}

.percentageir-1890:after {
  width: 85.05%;
}

.percentageir-1891:after {
  width: 85.095%;
}

.percentageir-1892:after {
  width: 85.14%;
}

.percentageir-1893:after {
  width: 85.185%;
}

.percentageir-1894:after {
  width: 85.23%;
}

.percentageir-1895:after {
  width: 85.275%;
}

.percentageir-1896:after {
  width: 85.32%;
}

.percentageir-1897:after {
  width: 85.365%;
}

.percentageir-1898:after {
  width: 85.41%;
}

.percentageir-1899:after {
  width: 85.455%;
}

.percentageir-1900:after {
  width: 85.5%;
}

.percentageir-1901:after {
  width: 85.545%;
}

.percentageir-1902:after {
  width: 85.59%;
}

.percentageir-1903:after {
  width: 85.635%;
}

.percentageir-1904:after {
  width: 85.68%;
}

.percentageir-1905:after {
  width: 85.725%;
}

.percentageir-1906:after {
  width: 85.77%;
}

.percentageir-1907:after {
  width: 85.815%;
}

.percentageir-1908:after {
  width: 85.86%;
}

.percentageir-1909:after {
  width: 85.905%;
}

.percentageir-1910:after {
  width: 85.95%;
}

.percentageir-1911:after {
  width: 85.995%;
}

.percentageir-1912:after {
  width: 86.04%;
}

.percentageir-1913:after {
  width: 86.085%;
}

.percentageir-1914:after {
  width: 86.13%;
}

.percentageir-1915:after {
  width: 86.175%;
}

.percentageir-1916:after {
  width: 86.22%;
}

.percentageir-1917:after {
  width: 86.265%;
}

.percentageir-1918:after {
  width: 86.31%;
}

.percentageir-1919:after {
  width: 86.355%;
}

.percentageir-1920:after {
  width: 86.4%;
}

.percentageir-1921:after {
  width: 86.445%;
}

.percentageir-1922:after {
  width: 86.49%;
}

.percentageir-1923:after {
  width: 86.535%;
}

.percentageir-1924:after {
  width: 86.58%;
}

.percentageir-1925:after {
  width: 86.625%;
}

.percentageir-1926:after {
  width: 86.67%;
}

.percentageir-1927:after {
  width: 86.715%;
}

.percentageir-1928:after {
  width: 86.76%;
}

.percentageir-1929:after {
  width: 86.805%;
}

.percentageir-1930:after {
  width: 86.85%;
}

.percentageir-1931:after {
  width: 86.895%;
}

.percentageir-1932:after {
  width: 86.94%;
}

.percentageir-1933:after {
  width: 86.985%;
}

.percentageir-1934:after {
  width: 87.03%;
}

.percentageir-1935:after {
  width: 87.075%;
}

.percentageir-1936:after {
  width: 87.12%;
}

.percentageir-1937:after {
  width: 87.165%;
}

.percentageir-1938:after {
  width: 87.21%;
}

.percentageir-1939:after {
  width: 87.255%;
}

.percentageir-1940:after {
  width: 87.3%;
}

.percentageir-1941:after {
  width: 87.345%;
}

.percentageir-1942:after {
  width: 87.39%;
}

.percentageir-1943:after {
  width: 87.435%;
}

.percentageir-1944:after {
  width: 87.48%;
}

.percentageir-1945:after {
  width: 87.525%;
}

.percentageir-1946:after {
  width: 87.57%;
}

.percentageir-1947:after {
  width: 87.615%;
}

.percentageir-1948:after {
  width: 87.66%;
}

.percentageir-1949:after {
  width: 87.705%;
}

.percentageir-1950:after {
  width: 87.75%;
}

.percentageir-1951:after {
  width: 87.795%;
}

.percentageir-1952:after {
  width: 87.84%;
}

.percentageir-1953:after {
  width: 87.885%;
}

.percentageir-1954:after {
  width: 87.93%;
}

.percentageir-1955:after {
  width: 87.975%;
}

.percentageir-1956:after {
  width: 88.02%;
}

.percentageir-1957:after {
  width: 88.065%;
}

.percentageir-1958:after {
  width: 88.11%;
}

.percentageir-1959:after {
  width: 88.155%;
}

.percentageir-1960:after {
  width: 88.2%;
}

.percentageir-1961:after {
  width: 88.245%;
}

.percentageir-1962:after {
  width: 88.29%;
}

.percentageir-1963:after {
  width: 88.335%;
}

.percentageir-1964:after {
  width: 88.38%;
}

.percentageir-1965:after {
  width: 88.425%;
}

.percentageir-1966:after {
  width: 88.47%;
}

.percentageir-1967:after {
  width: 88.515%;
}

.percentageir-1968:after {
  width: 88.56%;
}

.percentageir-1969:after {
  width: 88.605%;
}

.percentageir-1970:after {
  width: 88.65%;
}

.percentageir-1971:after {
  width: 88.695%;
}

.percentageir-1972:after {
  width: 88.74%;
}

.percentageir-1973:after {
  width: 88.785%;
}

.percentageir-1974:after {
  width: 88.83%;
}

.percentageir-1975:after {
  width: 88.875%;
}

.percentageir-1976:after {
  width: 88.92%;
}

.percentageir-1977:after {
  width: 88.965%;
}

.percentageir-1978:after {
  width: 89.01%;
}

.percentageir-1979:after {
  width: 89.055%;
}

.percentageir-1980:after {
  width: 89.1%;
}

.percentageir-1981:after {
  width: 89.145%;
}

.percentageir-1982:after {
  width: 89.19%;
}

.percentageir-1983:after {
  width: 89.235%;
}

.percentageir-1984:after {
  width: 89.28%;
}

.percentageir-1985:after {
  width: 89.325%;
}

.percentageir-1986:after {
  width: 89.37%;
}

.percentageir-1987:after {
  width: 89.415%;
}

.percentageir-1988:after {
  width: 89.46%;
}

.percentageir-1989:after {
  width: 89.505%;
}

.percentageir-1990:after {
  width: 89.55%;
}

.percentageir-1991:after {
  width: 89.595%;
}

.percentageir-1992:after {
  width: 89.64%;
}

.percentageir-1993:after {
  width: 89.685%;
}

.percentageir-1994:after {
  width: 89.73%;
}

.percentageir-1995:after {
  width: 89.775%;
}

.percentageir-1996:after {
  width: 89.82%;
}

.percentageir-1997:after {
  width: 89.865%;
}

.percentageir-1998:after {
  width: 89.91%;
}

.percentageir-1999:after {
  width: 89.955%;
}

.percentageir-2000:after {
  width: 90%;
}

html, body {
  height: 500px;
  font-family: "fira-sans-2", sans-serif;
  color: #333;
}

@media screen and (min-width: 830px) {
  .graph-container {
    margin-left: 30% !important;
  }
}
.invisiRow {
  min-height: 20px;
}

.perc_containerir {
  background-image: linear-gradient(109.6deg, rgb(24, 138, 141) 11.2%, rgb(6, 214, 160) 91.1%);
  border-radius: 6px;
  max-width: 15rem !important;
  border: 1px solid rgb(248, 248, 248);
  box-shadow: 2px 2px rgb(248, 248, 248);
}/*# sourceMappingURL=IRatingGraphMobile.css.map */