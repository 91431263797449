$brandColor: #4ADCBD;
$darkGreen: rgb(34, 39, 37);
$darkGray: rgb(102, 102, 102);
$darkGray1: rgb(169, 169, 169);
$accentColor: rgb(38, 151, 183);
$darkGreen1: rgb(24, 47, 40);
$darkGreen1a: rgba(24, 47, 40, 0.896);
$darkGreen2: rgb(37, 82, 71);
$darkGreen2a:rgba(37, 82, 71, 0.758);
$brandColor1: rgba(6, 214, 158, 0.136);
$brandColor2: rgba(24, 80, 63, 0.48);
$brandColor2a: rgba(24, 80, 63, 0.274);
$grayBack: rgba(241, 241, 241, 0.911);
$grayBack1: rgba(230, 230, 230, 0.8);