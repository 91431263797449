@import "../variables/Variables.scss";


.navbar {
    // background-image: linear-gradient(120deg,  $grayBack, 75%, $grayBack1);
    max-height: 3rem;
}

.navbar a {
    color: $darkGray;
    line-height: 3rem;
    font-size: .6rem;
}

.navbar a:hover {
    color: $brandColor;
}

.nav-name a{
    font-size: large;
    padding-left: 2rem;
    
}

.nav-name::after {
    content: ".pro";
    font-size: 1rem;
    color: $brandColor;
}

@media (max-width: 800px) {
    a {
        white-space: nowrap;
    }

    .nav-link {
        padding-left: 2px !important;
    }

    .nav-name a{
        padding-left: 5px;
    }
}