@import "../variables/Variables.scss";

dl {
    display: flex;
    background-color: white;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    position: relative;
    padding: 20px;
  }
  
  dt {
    align-self: flex-start;
    width: 100%;
    font-weight: 700;
    display: block;
    text-align: center;
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: 130px;
  }


  .info-text {
    white-space: nowrap;
    text-align: left;
    font-size: 1rem;
    color: $darkGray;
    line-height: 100%;
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .btn-primary {
    background-color: $darkGreen !important;
    border-color: $darkGreen !important;
    max-height: 2rem;
    line-height: .8rem !important;
    font-size: small !important;
    border-radius: 2px !important;
    margin-top: .6rem;
  }
  
  .text {
    font-weight: 400;
    display: flex;
    align-items: center;
    text-align: end;
    height: 40px;
    width: 130px;
    background-color: white;
    position: absolute;
    left: 0;
    justify-content: flex-end;
  }

  .text-after{
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 40px;
    width: 130px;
    background-color: white;
    position: absolute;
    left: 140px;
    justify-content: flex-start;

  }
  
  .percentage {
    font-size: .8em;
    line-height: 1;
    text-transform: uppercase;
    width: 100%;
    height: 40px;
    margin-left: 150px;
    
    
    &:after {
      content: "";
      display: block;
      background-color: $brandColor;
      width: 50px;
      margin-bottom: 10px;
      height: 20%;
      border-radius: 10px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color .3s ease;
      cursor: pointer;
    }
    &:hover,
    &:focus {
      &:after {
         background-color: #aaa; 
      }
    }
  }
  
  @for $i from 1 through 100 {
    .percentage-#{$i} {
      &:after {
        $value: ($i * 1%);
        width: $value;
      }
    }
  }
  
  html, body {
    height: 500px;
    font-family: "fira-sans-2",sans-serif;
    color: #333;
  }

  @media screen and (min-width: 830px) {
    .graph-container{
        margin-left: 30% !important; 
      }
}