.navbar {
  max-height: 3rem;
}

.navbar a {
  color: rgb(102, 102, 102);
  line-height: 3rem;
  font-size: 0.6rem;
}

.navbar a:hover {
  color: #4ADCBD;
}

.nav-name a {
  font-size: large;
  padding-left: 2rem;
}

.nav-name::after {
  content: ".pro";
  font-size: 1rem;
  color: #4ADCBD;
}

@media (max-width: 800px) {
  a {
    white-space: nowrap;
  }
  .nav-link {
    padding-left: 2px !important;
  }
  .nav-name a {
    padding-left: 5px;
  }
}/*# sourceMappingURL=NavBar.css.map */