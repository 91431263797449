.meme_row {
  margin-bottom: 2rem;
  font-weight: lighter;
}

.meme_col {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
  font-weight: lighter;
  font-size: 1.4rem;
}

.ir_text {
  padding-top: 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
  line-height: 2rem;
  font-weight: lighter;
}

.input-form-perc {
  margin-top: 10px;
  margin-bottom: 1.2rem;
  max-width: 14rem;
  text-align: center;
  max-height: 2rem;
  border-radius: 3px !important;
}

.perc_text {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
  line-height: 2rem;
  font-weight: lighter;
  margin-bottom: 0.8rem;
}

.perc_result {
  text-align: center;
  line-height: 2rem;
  font-weight: lighter;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.disclaimer_text {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: lighter;
  margin-bottom: 2rem;
  color: gray;
  font-size: small;
}

.invisiRow {
  min-height: 20px;
}

.perc_container {
  background-image: linear-gradient(109.6deg, rgb(24, 138, 141) 11.2%, rgb(6, 214, 160) 91.1%);
  border-radius: 6px;
  max-width: 15rem !important;
  border: 1px solid rgb(248, 248, 248);
  box-shadow: 2px 2px rgb(248, 248, 248);
  color: white;
}

.graph_container {
  max-height: 35rem;
  min-width: 100%;
}

canvas {
  background-image: linear-gradient(76.9deg, rgb(255, 255, 255) 8.1%, rgb(248, 248, 248) 90.8%);
  padding-bottom: 10px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .graph_container {
    max-height: 25rem;
  }
}
.dropdown-toggle {
  background-color: rgb(17, 29, 19) !important;
  border-color: rgb(248, 248, 248) !important;
  color: rgb(239, 239, 239) !important;
  margin-top: 1rem;
  margin-left: 1rem;
  max-width: 15rem;
  margin-bottom: 0rem;
}/*# sourceMappingURL=IRatingGraph.css.map */