@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
* {
  font-family: "Roboto" !important;
}

.memberNameRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 3rem;
  background: -webkit-linear-gradient(45deg, rgb(37, 82, 71), rgb(6, 214, 160));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.categoryNameRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-left: 1rem;
  align-items: center;
  font-size: 2rem;
  background-color: rgba(241, 241, 241, 0.911);
  border-top-left-radius: 2px;
  color: rgb(37, 82, 71);
  -webkit-clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
  width: 12rem;
}

.statsNameRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0px;
  margin-top: 2rem;
  font-size: 1rem;
  color: rgb(82, 82, 82);
  background-image: linear-gradient(45deg, rgb(225, 225, 225), rgb(235, 235, 235));
  background-size: 100% 5px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.statsValueRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 2rem;
  height: 6rem;
  font-size: 1rem;
  color: gray;
  background-color: rgb(250, 250, 250);
}

.avgsNameRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0px;
  margin-top: 0rem;
  font-size: 1rem;
  color: rgb(82, 82, 82);
  background-image: linear-gradient(45deg, rgb(225, 225, 225), rgb(235, 235, 235));
  background-size: 100% 5px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.avgsValueRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem !important;
  margin-top: 2rem;
  height: 3rem;
  font-size: 1rem;
  color: gray;
  background-color: rgb(250, 250, 250);
}

.valueColItem {
  border-right: 2px solid rgb(245, 245, 245);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mem_stats_value_row {
  font-size: 1.4rem;
}

.mem_stats_value_name_row {
  white-space: nowrap;
  font-size: 0.8rem;
}

.mem_stats_subCol {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.road_container,
.oval_container {
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 2px 2px 2px 1px rgba(240, 240, 240, 0.593);
}

.container1234 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  .mem_stats_value_row {
    padding-top: 0.6rem;
  }
  .statsValueRow {
    height: auto;
  }
}/*# sourceMappingURL=MemberStats.css.map */