@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
* {
  font-family: "Roboto" !important;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.landing-green-wall {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  top: 35%;
  background: #00A488;
  margin: 0px !important;
  padding: 0px !important;
}

.landing-background-accent1 {
  position: absolute;
  height: 50rem;
  right: 12px;
  bottom: 0px;
  -webkit-clip-path: polygon(100% 100%, 100% 45%, 90% 35%, 68% 100%);
          clip-path: polygon(100% 100%, 100% 45%, 90% 35%, 68% 100%);
  background: #4ADCBD;
}

.landing-background-accent2 {
  position: absolute;
  height: 50rem;
  right: 10px;
  bottom: 0px;
  -webkit-clip-path: polygon(0% 70%, 19% 22%, 0% 0%);
          clip-path: polygon(0% 70%, 19% 22%, 0% 0%);
  background: #4ADCBD;
  margin: 0px !important;
  padding: 0px !important;
}

.landing-option-text-row {
  color: white;
  white-space: nowrap;
  padding-top: 100px;
  padding-left: 20% !important;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.landing-option-text-row:hover {
  transform: scaleY(1.05);
}

.fa-clipboard,
.fa-car-burst,
.fa-users-line,
.fa-paper-plane {
  width: 5rem !important;
}

.landing-selection-text {
  width: 50% !important;
  max-height: 5rem;
  display: flex;
  justify-content: left;
  line-height: 32px;
  font-weight: 300;
}

.landing-top-text-row {
  color: black;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  padding-top: 6rem;
  width: 100%;
}

.landing-top-text-row::after {
  content: " all in one place.";
  font-size: 1rem;
  color: #4ADCBD;
}

.container1234 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=LandingPage.css.map */